
.item-footer-dui {
    padding-bottom: 30px;    
    position: relative;
    z-index: 1;


    .title-cate {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        padding-bottom: 24px;
        color: var(--color-title);
        margin-bottom: 0;
    }

    .item-link-footer {
        margin-bottom: 30px;

        div {
            padding-bottom: 16px;

            a {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: var(--color-sub);

                &:hover {
                    color: #1570EF;
                }
            }
        }
    }
}

.copyright{
    z-index: 1;
    position: relative;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    .item-copyright{
        display: flex;
        align-items: center;
        justify-content: center;
        .created {
            font-style: normal;
            font-weight: 200;
            font-size: 14px;
            color: var(--SubText);
            line-height: 150%;
            padding-bottom: 16px;
            a {
                color: var(--colorTitleText);
                font-weight: 400;
                z-index: 1;
                position: relative;
                &:hover{
                    color: #1570EF
                }
            }
        }
        .content-copyright {
            font-style: normal;
            font-weight: 200;
            font-size: 14px;
            color: var(--SubText);
            line-height: 150%;
            z-index: 99;
            padding-bottom: 16px
        }
    }
}



@media (min-width: 320px) {
    .item-footer-dui{
        .pl-80{
            padding-left: 30px;
        }
    }

}

@media (min-width: 414px) {
    .item-footer-dui{
        .pl-80{
            padding-left: 30px;
        }
    }
}


@media (min-width: 768px) {
    .pl-80{
        padding-left: 0px;
    }
}

@media (min-width: 992px) {
    .item-footer-dui {

        .item-link-footer {
            margin-bottom: 0px;
        }
    }
}
