html {
    background: var(--lightBg);
    --BackgroundAlertSuccess: #1e2023;
    --BackgroundAlertError: #1e2023;
    --BackgroundAlertWarning: #1e2023;
    --BackgroundAlertBlue: #1e2023;
    --scrollBarPage: #194185;
    --BgiconDui: #1570EF;
    --background-Layout-component: #1e2023;
    --borderInput: #272a34;
    --bgCode: #18191C;
    --activeChip: #141417;
    --background-decor-blur: radial-gradient(circle at center, #09182f 0, rgba(26, 26, 26, 0) 70%);
    --BoxShadowDui: 0 12px 24px 6px rgb(85 87 90 / 8%), 0 24px 48px 6px rgb(65 65 65 / 8%);
    --BlackDui: #18191C;
    --lightBtn: #141417;
    --lightBg: #101828;
    --lightColor: #F9FAFB;
    --colorTitleText: #F9FAFB;
    --SubText: #98A2B3;
    --backgroundLevelTable: #141417;
    --img-mediaiconic: url(/img/logo-mediaiconic-light.png);
    --img-component-1: url(/img/01-dark.png);
    --img-component-2: url(/img/02-dark.png);
    --img-component-3: url(/img/03-dark.png);
     // New
    --img-figma: url(/img/figma-dark.png);
    --img-icon-ai: url(/img/icon-ai-dark.png);
    --img-gradient: url(/img/gradient-dark.png);
    --img-gradient-mobile: url(/img/Gradient-dark-mobile.webp);
    --img-header-follow-ui: url(/img/img-header-follow-ui-dark.webp);
    --icon-features-1:url(/img/features-1.svg);
    --icon-features-2:url(/img/features-2.svg);
    --icon-features-3:url(/img/features-3.svg);
    --icon-features-4:url(/img/features-4.svg);
    --icon-features-5:url(/img/features-5.svg);
    --icon-features-6:url(/img/features-6.svg);
    --icon-features-7:url(/img/features-7.svg);
    --icon-features-8:url(/img/features-8.svg);
    --icon-caro:url(/img/caro.png);
    --icon-connect-follow:url(/img/icon-connect-dark.png);
    --raibow-color: linear-gradient(-45deg, #FF50E3 0%, #8251DE 47.92%, #1570EF 100%);
    --icon-pricing: url(/img/icon-pricing.svg);
    --globe-img-team-dark:url(/img/globe-img-team-dark.png);
    --background-gradient-color-before:linear-gradient(90deg, #101828 50%, rgba(16, 24, 40, 0) 100%);
    --background-gradient-color-after:linear-gradient(-90deg, #101828 50%, rgba(16, 24, 40, 0) 100%);
    --background-gradient-color-bottom:linear-gradient(180deg, rgba(16, 24, 40, 0.06) 0%, #101828 86.46%);
    --background-gradient-color-bottomEX:linear-gradient(180deg, rgba(16, 24, 40, 0.06) 0%, #101828 40%);
    --background-gradient-color-center:linear-gradient(90deg, rgba(16, 24, 40, 0) 0%, #101828 33.33%, #101828 51.04%, #101828 66.67%, rgba(16, 24, 40, 0) 100%);
    --mix-blend-mode:color-dodge;
    --background-color-opacity:rgba(255, 255, 255, 0.02);
    --backdrop-filter: blur(8px);
    --bolor-color-opacity: 1px solid rgba(255, 255, 255, 0.08);
    --bolor-color-opacity2: 2px solid rgba(255, 255, 255, 0.08);
    --bolor-color-opacity-dash: 1px dashed rgba(255, 255, 255, 0.08);
    --backgrond-mixEX: #1F2534;
    --border-mixEX: 1px solid #313644;
    --color-title: #FFFFFF;
    --color-title-sub:#98A2B3;
    --color-sub: #98A2B3;
    --background-nav:rgb(18 24 40 / 90%);
    --color-title-blue-tone:#D1E9FF;
    --background-features-before:#ffffff3d;
    --background-features-after:linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.06) 100%);
    --background-features-after-top:linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .06) 100%);
    --border-gradient-feature-old:linear-gradient(180deg, rgba(255, 255, 255, .12) -.89%, rgba(255, 255, 255, 0) 100%);
    --border-gradient-feature-even:linear-gradient(0, rgba(255, 255, 255, .12) -.89%, rgba(255, 255, 255, 0) 100%);
    --border-gradient-feature-last:linear-gradient(90deg, rgba(255, 255, 255, 0) -.89%, rgba(255, 255, 255, .08) 24.33%, rgba(255, 255, 255, .08) 49.55%, rgba(255, 255, 255, .08) 74.78%, rgba(255, 255, 255, 0) 100%);
    --background-gradient-build-before:linear-gradient(-90deg, #101828e0 20%, rgba(16, 24, 40, 0) 100%);
    --background-gradient-build-before-bottom:linear-gradient(180deg, rgba(16, 24, 40, 0.06) 0%, #101828 100%);
    --background-rada:conic-gradient(transparent 70%, rgba(255, 255, 255, 0.2));
    --background-card-effect:#192130;
    --border-card-mini:1px solid #58627d;
    --background-card-mini:#2f3544;
    --icon-reset-iterate:url(/img/reset-icon.svg);
    --background-image-glowing-color:conic-gradient(from 0 at 50% 50%, rgba(255, 255, 255, 0.5) 0deg, rgba(255, 255, 255, 0) 60deg, rgba(255, 255, 255, 0) 310deg, rgba(255, 255, 255, 0.5) 360deg);
    --glowing-box-stars:conic-gradient(from 0 at 50% 50%, rgba(255, 255, 255, 0.5) 0deg, rgba(255, 255, 255, 0) 60deg, rgba(255, 255, 255, 0) 310deg, rgba(255, 255, 255, 0.5) 360deg);
    --glowing-box-borders-masker:linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    --glowing-box-borders:conic-gradient(from 0 at 50% 50%, rgba(255, 255, 255, 0.5) 0deg, rgba(255, 255, 255, 0) 60deg, rgba(255, 255, 255, 0) 310deg, rgba(255, 255, 255, 0.5) 360deg);
    --glowing-box-button:1px solid rgba(255, 255, 255, 0.08);
    --glowing-box-button--after:radial-gradient(85% 120% at 50% 120%, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
    --icon-color-tick:white;
    --bgCode: rgba(255, 255, 255, 0.02);
    --logo-main:url(/img/logo-uicustom-dark.png);
    --bg-ui-card-gap:linear-gradient(1.84deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.04) 100%);
    --bg-card-line-features-before:linear-gradient(to bottom, rgba(255, 255, 255, 0.12) -0.89%, rgba(255, 255, 255, 0) 100%);
    --bg-card-line-features-after:linear-gradient(to top, rgba(255, 255, 255, 0.12) -0.89%, rgba(255, 255, 255, 0) 100%);
}

html[data-theme='light'] {
    // Light
    background: var(--lightBg);
    --scrollBarPage: #1D2939;
    --background-Layout-component: #FFF;
    --SubText: #667085;
    --activeChip: #E4E7EC;
    --BgiconDui: #D1E9FF;
    --bgCode: #0b101b;
    --background-decor-blur: radial-gradient(circle at center, #95c6f3 0, rgba(26, 26, 26, 0) 80%);
    --BoxShadowDui: 0 24px 48px 10px rgba(152, 162, 179, .06), 0 32px 64px 10px rgba(152, 162, 179, .06);
    --backgroundLevelTable: #EFF8FF;
    --BlackDui: #FFF;
    --lightBtn: #f4f7f8;
    --borderInput: #E4E7EC;
    --lightBg: #EFF8FF;
    --lightColor: #1D2939;
    --colorTitleText: #1D2939;
    --BackgroundAlertSuccess: #ECFDF3;
    --BackgroundAlertError: #FEF3F2;
    --BackgroundAlertWarning: #FFFAEB;
    --BackgroundAlertBlue: #EFF8FF;
    --img-mediaiconic: url(/img/logo-mediaiconic-dark.png);
    --img-component-1: url(/img/01-light.png);
    --img-component-2: url(/img/02-light.png);
    --img-component-3: url(/img/03-light.png);
    --color-title: #1D2939;
    // New
    --img-figma: url(/img/figma-light.png);
    --img-icon-ai: url(/img/icon-ai-light.png);
    --img-icon-light: url(/img/icon-ai-light.png);
    --img-gradient: url(/img/gradient-light.png);
    --img-gradient-mobile: url(/img/Gradient-light-mobile.webp);
    --img-header-follow-ui: url(/img/img-header-follow-ui-light.webp);
    --icon-features-1:url(/img/features-1-light.svg);
    --icon-features-2:url(/img/features-2-light.svg);
    --icon-features-3:url(/img/features-3-light.svg);
    --icon-features-4:url(/img/features-4-light.svg);
    --icon-features-5:url(/img/features-5-light.svg);
    --icon-features-6:url(/img/features-6-light.svg);
    --icon-features-7:url(/img/features-7-light.svg);
    --icon-features-8:url(/img/features-8-light.svg);
    --icon-caro:url(/img/caro-light.png);
    --icon-connect-follow:url(/img/icon-connect-light.png);
    --raibow-color: linear-gradient(-45deg, #FF50E3 0%, #8251DE 47.92%, #1570EF 100%);
    --icon-pricing: url(/img/icon-pricing-light.svg);
    --globe-img-team-dark:url(/img/globe-img-team-light.png);
    --background-gradient-color-before:linear-gradient(270deg, rgba(239, 248, 255, 0.00) 0%, #EFF8FF 86.46%);
    --background-gradient-color-after:linear-gradient(90deg, rgba(239, 248, 255, 0.00) 0%, #EFF8FF 86.46%);
    --background-gradient-color-bottom:linear-gradient(180deg, rgba(239, 248, 255, 0.06) 0%, #EFF8FF 86.46%);
    --background-gradient-color-bottomEX:linear-gradient(180deg, rgba(239, 248, 255, 0.06) 0%, #EFF8FF 40%);
    --background-gradient-color-center:linear-gradient(90deg, rgba(39, 248, 255, 0.00) 0%, #EFF8FF 33.33%, #EFF8FF 51.04%, #EFF8FF 66.67%, rgba(39, 248, 255, 0.00) 100%);
    --mix-blend-mode:unset;
    --background-color-opacity:rgba(21, 112, 239, 0.02);
    --backdrop-filter: blur(8px);
    --bolor-color-opacity: 1px solid rgba(21, 112, 239, 0.08);
    --bolor-color-opacity2: 2px solid rgba(21, 112, 239, 0.08);
    --bolor-color-opacity-dash: 1px dashed rgba(21, 112, 239, 0.08);
    --backgrond-mixEX: #E1EFFF;
    --border-mixEX: 1px solid #D1E5FE;
    --color-title: #1D2939;
    --color-title-sub:#475467;
    --color-sub: #667085;
    --background-nav:rgba(239, 248, 255, 0.08);
    --color-title-blue-tone:#475467;
    --background-features-before:#bbd7f5;
    --background-features-after:linear-gradient(to bottom, rgba(21, 112, 239, 0) 0%, rgba(21, 112, 239, 0.06) 100%);
    --background-features-after-top:linear-gradient(to top, rgba(21, 112, 239, 0) 0%, rgba(21, 112, 239, .06) 100%);
    --border-gradient-feature-old:linear-gradient(180deg, rgba(21, 112, 239, .12) -.89%, rgba(21, 112, 239, 0) 100%);
    --border-gradient-feature-even:linear-gradient(0, rgba(21, 112, 239, .12) -.89%, rgba(21, 112, 239, 0) 100%);
    --border-gradient-feature-last:linear-gradient(90deg, rgba(21, 112, 239, 0) -.89%, rgba(21, 112, 239, .08) 24.33%, rgba(21, 112, 239, .08) 49.55%, rgba(21, 112, 239, .08) 74.78%, rgba(21, 112, 239, 0) 100%);
    --background-gradient-build-before:linear-gradient(-90deg, #e1efff 20%, rgba(16, 24, 40, 0) 100%);
    --background-gradient-build-before-bottom:linear-gradient(180deg, rgba(239, 248, 255, 0.06) 0%, #e1efff 86.46%);
    --background-rada:conic-gradient(transparent 70%, rgba(21, 112, 239, 0.15));
    --background-card-effect:#d5e7fb;
    --border-card-mini:1px solid #c6defa;
    --background-card-mini:#e1efff;
    --icon-reset-iterate:url(/img/reset-icon-light.svg);
    
    --background-image-glowing-color:conic-gradient(from 0 at 50% 50%, rgba(21, 112, 239, 0.8) 0deg, rgba(21, 112, 239, 0) 60deg, rgba(21, 112, 239, 0) 310deg, rgba(21, 112, 239, 0.8) 360deg);
    --glowing-box-stars:conic-gradient(from 0 at 50% 50%, rgba(21, 112, 239, 0.8) 0deg, rgba(21, 112, 239, 0) 60deg, rgba(21, 112, 239, 0) 310deg, rgba(21, 112, 239, 0.8) 360deg);
    --glowing-box-borders-masker:linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    --glowing-box-borders:conic-gradient(from 0 at 50% 50%, rgba(21, 112, 239, 0.8) 0deg, rgba(21, 112, 239, 0) 60deg, rgba(21, 112, 239, 0) 310deg, rgba(21, 112, 239, 0.8) 360deg);
    --glowing-box-button:1px solid rgba(21, 112, 239, 0.08);
    --glowing-box-button--after:radial-gradient(85% 120% at 50% 120%, rgba(21, 112, 239, 0.24) 0%, rgba(21, 112, 239, 0) 100%);
    --icon-color-tick:#1570EF;
    --bgCode: black;
    --logo-main:url(/img/logo-uicustom-light.png);
    --bg-ui-card-gap:linear-gradient(1.84deg, rgba(21, 112, 239, 0) 0%, rgba(21, 112, 239, 0.04) 100%);
    --bg-card-line-features-before:linear-gradient(to bottom, rgba(21, 112, 239, 0.12) -0.89%, rgba(21, 112, 239, 0) 100%);
    --bg-card-line-features-after:linear-gradient(to top, rgba(21, 112, 239, 0.12) -0.89%, rgba(21, 112, 239, 0) 100%);
}

.nav-wrapper{
    padding:20px 12px;
    background: var(--background-nav);
    backdrop-filter: blur(8px);
    .navbar{
        padding: 8px 15px;
        .item-logo{
            display: flex;
            column-gap: 20px;
            align-items: center;
            .logo-main{
                background: var(--logo-main) no-repeat;
                width: 120px;
                height: 44px;
                background-size: contain;
            }
        }

        a:hover{
            color:white;
        }
    }
    .nav{
       a{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color:var(--color-title)
       }
    }
    .item-preview-figma{
        display: flex;
        gap: 12px;
        align-items: center;
        padding-right: 15px;
        .item-dowload-system{
            .icon-figma{
                background: var(--img-figma) no-repeat;
                width: 24px;
                height: 24px;
            }
           a{
            padding: 8px 12px;
            display: flex;
            gap: 4px;
           }
        }
        .item-preview-pro{
        
            // ok 1
            $color_1: transparent;

            @keyframes borderTurn {
                0% {
                    transform: rotate(0);
                }
                to {
                    transform: rotate(360deg);
                }
            }
            @keyframes borderTurnWithTranslate {
                0% {
                    transform: translate(-50%, -50%) rotate(0);
                }
                to {
                    transform: translate(-50%, -50%) rotate(360deg);
                }
            }
            .glowing-box {
                isolation: isolate;
                overflow: hidden;
                border-radius: 999px;
                display: inline-block;
                position: relative;
            }
            .glowing-box-active {
                .glowing-box-animations {
                    opacity: 1;
                }
                .glowing-box-borders-masker {
                    opacity: 1;
                }
            }
            .glowing-box-animations {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                opacity: 0;
                pointer-events: none;
                transition: 1s ease opacity;
                &:before {
                    content: "";
                    float: left;
                    padding-top: 100%;
                }
                &:after {
                    clear: both;
                    content: "";
                    display: block;
                }
                * {
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }
            .glowing-box-borders {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                animation: borderTurn var(--animation-speed) infinite linear;
                background-image: var(--glowing-box-borders);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                animation-name: borderTurnWithTranslate;
                &:before {
                    content: "";
                    float: left;
                    padding-top: 100%;
                }
                &:after {
                    clear: both;
                    content: "";
                    display: block;
                }
            }
            .glowing-box-glow {
                animation: borderTurn var(--animation-speed) infinite linear;
                background-image: var(--background-image-glowing-color);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                filter: blur(8px);
                opacity: .12;
            }
            .glowing-box-stars {
                animation: borderTurn var(--animation-speed) infinite linear;
                background-image: var(--glowing-box-stars);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .glowing-box-stars-masker {
                -webkit-mask: url("data:image/svg+xml,%3Csvg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.0534 15.732C13.8444 15.283 14.2848 14.8489 14.7326 15.051C14.8296 15.0959 14.9043 15.1707 14.949 15.268C15.1506 15.717 14.7177 16.1511 14.2698 15.949C14.1728 15.9041 14.0982 15.8293 14.0534 15.732Z' fill='black'/%3E%3Cpath d='M18.8001 13.5093C19.0016 13.0603 18.5687 12.6263 18.1209 12.8283C18.0239 12.8732 17.9492 12.9481 17.9045 13.0453C17.6955 13.4944 18.1358 13.9284 18.5837 13.7264C18.6807 13.6815 18.7553 13.6066 18.8001 13.5093Z' fill='black'/%3E%3Cpath d='M0.949043 2.732C1.15057 2.28297 0.717663 1.84891 0.269836 2.05097C0.172806 2.09587 0.098162 2.17071 0.0533793 2.268C-0.155607 2.71703 0.284759 3.15109 0.732587 2.94903C0.829616 2.90413 0.90426 2.82929 0.949043 2.732Z' fill='black'/%3E%3Cpath d='M26.9489 7.732C27.151 7.28297 26.7169 6.84891 26.2679 7.05097C26.1706 7.09587 26.0958 7.17071 26.0508 7.268C25.8488 7.71703 26.2828 8.15109 26.7319 7.94903C26.8292 7.90413 26.904 7.82929 26.9489 7.732Z' fill='black'/%3E%3Cpath d='M13.0534 5.732C12.8444 5.28297 13.2848 4.84891 13.7326 5.05097C13.8296 5.09587 13.9043 5.17071 13.949 5.268C14.1506 5.71703 13.7177 6.15109 13.2698 5.94903C13.1728 5.90413 13.0982 5.82929 13.0534 5.732Z' fill='black'/%3E%3Cpath d='M10.0534 17.732C9.84439 17.283 10.2848 16.8489 10.7326 17.051C10.8296 17.0959 10.9043 17.1707 10.949 17.268C11.1506 17.717 10.7177 18.1511 10.2698 17.949C10.1728 17.9041 10.0982 17.8293 10.0534 17.732Z' fill='black'/%3E%3Cpath d='M15.0534 21.732C14.8444 21.283 15.2848 20.8489 15.7326 21.051C15.8296 21.0959 15.9043 21.1707 15.949 21.268C16.1506 21.717 15.7177 22.1511 15.2698 21.949C15.1728 21.9041 15.0982 21.8293 15.0534 21.732Z' fill='black'/%3E%3C/svg%3E%0A");
                mask: url("data:image/svg+xml,%3Csvg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.0534 15.732C13.8444 15.283 14.2848 14.8489 14.7326 15.051C14.8296 15.0959 14.9043 15.1707 14.949 15.268C15.1506 15.717 14.7177 16.1511 14.2698 15.949C14.1728 15.9041 14.0982 15.8293 14.0534 15.732Z' fill='black'/%3E%3Cpath d='M18.8001 13.5093C19.0016 13.0603 18.5687 12.6263 18.1209 12.8283C18.0239 12.8732 17.9492 12.9481 17.9045 13.0453C17.6955 13.4944 18.1358 13.9284 18.5837 13.7264C18.6807 13.6815 18.7553 13.6066 18.8001 13.5093Z' fill='black'/%3E%3Cpath d='M0.949043 2.732C1.15057 2.28297 0.717663 1.84891 0.269836 2.05097C0.172806 2.09587 0.098162 2.17071 0.0533793 2.268C-0.155607 2.71703 0.284759 3.15109 0.732587 2.94903C0.829616 2.90413 0.90426 2.82929 0.949043 2.732Z' fill='black'/%3E%3Cpath d='M26.9489 7.732C27.151 7.28297 26.7169 6.84891 26.2679 7.05097C26.1706 7.09587 26.0958 7.17071 26.0508 7.268C25.8488 7.71703 26.2828 8.15109 26.7319 7.94903C26.8292 7.90413 26.904 7.82929 26.9489 7.732Z' fill='black'/%3E%3Cpath d='M13.0534 5.732C12.8444 5.28297 13.2848 4.84891 13.7326 5.05097C13.8296 5.09587 13.9043 5.17071 13.949 5.268C14.1506 5.71703 13.7177 6.15109 13.2698 5.94903C13.1728 5.90413 13.0982 5.82929 13.0534 5.732Z' fill='black'/%3E%3Cpath d='M10.0534 17.732C9.84439 17.283 10.2848 16.8489 10.7326 17.051C10.8296 17.0959 10.9043 17.1707 10.949 17.268C11.1506 17.717 10.7177 18.1511 10.2698 17.949C10.1728 17.9041 10.0982 17.8293 10.0534 17.732Z' fill='black'/%3E%3Cpath d='M15.0534 21.732C14.8444 21.283 15.2848 20.8489 15.7326 21.051C15.8296 21.0959 15.9043 21.1707 15.949 21.268C16.1506 21.717 15.7177 22.1511 15.2698 21.949C15.1728 21.9041 15.0982 21.8293 15.0534 21.732Z' fill='black'/%3E%3C/svg%3E%0A");
                -webkit-mask-repeat: repeat;
                mask-repeat: repeat;
                -webkit-mask-size: auto;
                mask-size: auto;
            }
            .glowing-box-borders-masker {
                border-radius: 999px;
                content: "";
                height: 100%;
                inset: 0;
                left: 0;
                -webkit-mask: var(--glowing-box-borders-masker);
                mask: var(--glowing-box-borders-masker);
                mask-composite: xor;
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                opacity: 0;
                padding: 1px;
                pointer-events: none;
                position: absolute;
                top: 0;
                transition: 1s ease opacity;
                width: 100%;
            }
            .glowing-box-button {
                font-size: 16px;
                font-weight: 400;
                line-height: 150%;
                border: var(--glowing-box-button);
                border-radius: 999px;
                cursor: pointer;
                padding: 6px 24px;
                position: relative;
                z-index: 1;
                background: rgba(255, 255, 255, 0.02);
                a{
                    color:var(--color-title);
                }
                &:after {
                    background: var(--glowing-box-button--after);
                    border-radius: 999px;
                    content: "";
                    height: calc(100% + 4px);
                    left: -2px;
                    opacity: 0;
                    position: absolute;
                    top: -2px;
                    transition: 1s all;
                    width: calc(100% + 4px);
                }
                &:hover {
                    &:after {
                        opacity: .7;
                    }
                }
                span {
                    background: linear-gradient(180deg, rgba(255, 255, 255, .3) 8.85%, #FFFFFF 100%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: $color_1;
                    -webkit-text-fill-color: transparent;
                    display: block;
                }
            }
            
            // ok 2
        }
    }
}

@media only screen and(min-width: 768px) {
    .nav-wrapper{
        .navbar{
            padding: 6px 15px;
            .item-logo{
                    padding-top: 5px;
                img{
                    width: 140px;
                }
            }
        }
        .item-preview-figma{
            padding-right: 15px;
        }
    }
}


@media only screen and(min-width: 992px) {
    .nav-wrapper{
        padding: 20px 12px 8px 12px;
        background: var(--background-nav);
        .item-preview-figma{
            .item-dowload-system{
                a{
                    margin-top: -5px;
                }
            }
        }
    }
}

