$color_1: #160042;
$color_2: #6b7194;
$color_3: #642eff;
$color_4: #fff;
$font-family_1: Satoshi,sans-serif;
$font-family_2: Inter,sans-serif;
$background-color_1: transparent;
$background-color_2: #fff;
$background-color_3: #f3f5fb;


.w-embed {
	&:before {
		content: " ";
		grid-area: 1/1/2/2;
		display: table;
	}
	&:after {
		content: " ";
		grid-area: 1/1/2/2;
		display: table;
		clear: both;
	}
}

.feature-blog-content {
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	display: flex;
}
.blog-featured-post {
	box-shadow: none;
	border-radius: 20px;

}
.shadow-harsh {
	box-shadow: 11px 11px 24px rgba(33,42,103,.02),6px 6px 16px rgba(33,42,103,.02),3px 3px 8px rgba(33,42,103,.02),1px 1px 4px rgba(33,42,103,.02),1px 1px 1px rgba(33,42,103,.02);
}
.blog-feature-image {
	height: 352px;
	border-radius: 16px 4px 4px;
	overflow: hidden;
}
.badge-blog {
	color: white;
    border: var(--bolor-color-opacity);
    background: var(--background-color-opacity);
	border-radius: 32px;
	align-items: center;
	margin-bottom: 16px;
	padding: 4px 12px 4px 4px;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	display: flex;
}
.blog-featured {
	border: var(--bolor-color-opacity);
    background: var(--background-color-opacity);
	border-radius: 40px 40px 8px 8px;
	padding: 32px;
	.w-layout-grid {
		grid-row-gap: 16px;
		grid-column-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}
	h4 {
		margin-bottom: 10px;
		color: var(--color-title);
		margin-top: 0;
		margin-bottom: 0;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 130%;
		padding-bottom: 12px;
	}
	p {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		   color:var(--color-sub);
	}
	img {
		border: 0;
		max-width: 100%;
		vertical-align: middle;
		display: inline-block;
		max-width: 100%;
		display: inline-block;
	}
	
	.w-inline-block {
		max-width: 100%;
		display: inline-block;
	}
}
.blog-feature-grid {
	grid-column-gap: 42px;
	grid-row-gap: 42px;
	grid-template-rows: auto;
	align-items: center;
	justify-items: stretch;
}
.gradient-star {
	width: 24px;
	height: 24px;
	justify-content: center;
	align-items: center;
	margin-right: 4px;
	display: flex;
}
.margin-bottom-16 {
	margin-bottom: 16px;
}
.blog-image-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.author-block {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	text-align: left;
	align-items: center;
	margin-top: 16px;
	display: none;
}
.author-avatar {
	width: 64px;
	height: 64px;
	background-color: $background-color_3;
	border-radius: 100px;
}
.parargraph-regular {
	color: $color_2;
	margin-bottom: 0;
	font-size: 14px;
	line-height: 24px;
}
.author-name-l {
	color: $color_1;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}
.author-avatar-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.uicustom-gradient {
	color: var(--color-title);
}

@media screen and (max-width:991px) {
	.feature-blog-content {
		padding-left: 10px;
	}
	.blog-featured-post {
		margin-bottom: 10px;
	}
	.blog-feature-image {
		height: 360px;
		max-height: none;
		overflow: hidden;
	}
	.blog-feature-grid {
		grid-template-columns: 1fr;
	}
}
@media screen and (max-width:767px) {
	.feature-blog-content {
		padding-right: 10px;
	}
	.blog-feature-image {
		height: 340px;
	}
}
@media screen and (max-width:479px) {
	.blog-feature-image {
		height: 190px;
	}
	.blog-featured {
		padding: 24px;
	}
}


// List blog
$color_1: #160042;
$color_2: #6b7194;
$color_3: #642eff;
$color_4: #b6b9ce;
$color_5: #201b3d;
$font-family_1: Satoshi,sans-serif;
$font-family_2: Inter,sans-serif;
$background-color_1: transparent;
$background-color_2: #f7f8fd;
$background-color_3: #f7f5ff;
$background-color_4: #fff;





.blog-card-l {
	height: 100%;
	border: var(--bolor-color-opacity);
    background: var(--background-color-opacity);
	border-radius: 40px 40px 8px 8px;
	padding: 32px 40px;
	text-decoration: none;
	transition: box-shadow .3s;
	p {
		margin-top: 0;
		margin-bottom: 10px;
		color: var(--color-sub);    
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
	&:hover {
		box-shadow: 85px 85px 156px -32px rgba(57,59,106,.08);
	}
}

.blog-collection-list {
	margin-top: 40px;
}
.summary {
	margin-bottom: 0;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.title-centre {
	max-width: 800px;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}
.title-centre.larger-title {
	max-width: 860px;
}
.gradient-title {
	letter-spacing: .06em;
	text-transform: uppercase;
	margin-bottom: 8px;
	font-family: $font-family_1;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	background-image: linear-gradient(270deg, #FF50E3 0%, #8251DE 47.92%, #1570EF 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
.blog-detail-block {
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
	display: flex;
}
.badge-post-date {
	color: var(--color-sub);
	letter-spacing: .06em;
	text-transform: uppercase;
    border: var(--bolor-color-opacity);
    background: var(--background-color-opacity);
	border-radius: 100px;
	align-items: center;
	padding: 4px 16px;
	font-family: $font-family_1;
	font-size: 12px;
	font-weight: 700;
	line-height: 24px;
	display: flex;
}
.badge-category {
	color: var(--color-title);
	letter-spacing: .06em;
	text-transform: uppercase;
	border: var(--bolor-color-opacity);
    background: var(--background-color-opacity);
	border-radius: 100px;
	align-items: center;
	padding: 4px 16px;
	font-family: $font-family_1;
	font-size: 12px;
	font-weight: 700;
	line-height: 24px;
	display: flex;
}
.section-regular {
	padding: 72px 0;
	position: relative;
	overflow: visible;
	h3 {
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 130%;
		color: var(--color-title);
	}
	h5 {
		font-size:24px;
		font-style: normal;
		font-weight: 500;
		line-height: 130%;
		padding-bottom: 12px;
		color:var(--color-title);
	}
	.blog-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.blog-image-l {
		height: 310px;
		border-radius: 16px 4px 4px;
		margin-bottom: 16px;
		overflow: hidden;
	}
	.blog-card-content {
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}
	.blog-collection-l {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
		flex-wrap: wrap;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: grid;
		img {
			border: 0;
			max-width: 100%;
			vertical-align: middle;
			display: inline-block;
			max-width: 100%;
			display: inline-block;
		}
		
		.w-inline-block {
			max-width: 100%;
			display: inline-block;
		}
	}
}

.pb-160{
	padding-bottom: 160px;
}

@media screen and (max-width:991px) {
	.blog-card-content {
		padding-right: 20px;
	}
	.blog-collection-l {
		grid-template-columns: 1fr;
	}
	.section-regular {
		padding-top: 40px;
		padding-right: 3%;
	}
}
@media screen and (max-width:767px) {
	.blog-cms-item {
		width: 100%;
	}
	.blog-collection-l {
		grid-template-columns: 1fr;
	}
	.section-regular {
		padding-top: 60px;
		padding-bottom: 100px;
	}
}
@media screen and (max-width:479px) {
	.blog-image-l {
		height: 200px;
	}
	.blog-card-content {
		padding-left: 0;
		padding-right: 10px;
	}
	.blog-card-l {
		padding: 24px;
	}
	.badge-post-date {
		display: none;
	}
}


@media only screen and(min-width: 992px) {
	.blog-featured {
		.w-layout-grid {
			grid-template-columns: 1fr 1fr;
		}
		h4 {
			font-size: 32px;
		}
	}

	.section-regular {
		h3 {
			font-size: 32px;
		}
	}

	.section-regular{
		.blog-collection-l{
			grid-template-columns: 1fr 1fr;
		}
	}
}
