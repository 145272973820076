
a {
	color: var(--color-title);
	text-decoration: none;
	&:active {
		outline: 0;
	}
	&:hover {
		outline: 0;
	}
}



input {
	color: var(--color-title);
	margin: 0;
	line-height: normal;
}



.w-icon-dropdown-toggle {
	&:before {
		content: "";
	}
	width: 1em;
	height: 1em;
	margin: auto 20px auto auto;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
}


.w-button {
	color: var(--color-title);
	line-height: inherit;
	cursor: pointer;
	background-color: $background-color_2;
	border: 0;
	border-radius: 0;
	padding: 9px 15px;
	text-decoration: none;
	display: inline-block;
}
h5 {
	margin-bottom: 10px;
	font-weight: 700;
	margin-top: 10px;
	font-size: 14px;
	line-height: 20px;
	color: var(--color-title);
	letter-spacing: -.02em;
	margin-top: 0;
	margin-bottom: 8px;
	font-size: 32px;
	font-weight: 700;
	line-height: 40px;
}


.w-embed {
	&:before {
		content: " ";
		grid-area: 1/1/2/2;
		display: table;
	}
	&:after {
		content: " ";
		grid-area: 1/1/2/2;
		display: table;
		clear: both;
	}
}
.w-form {
	margin: 0 0 15px;
}


.w-input {
	width: 100%;
	height: 38px;
	color: var(--color-title);
	vertical-align: middle;
    background: var(--background-color-opacity);
    border: var(--bolor-color-opacity);
	margin-bottom: 10px;
	padding: 8px 12px;
	font-size: 14px;
	line-height: 1.42857;
	display: block;
	&:focus {
		border-color: var(--bolor-color-opacity);
		outline: 0;
	}
}


.w-condition-invisible {
	display: none !important;
}
.w-pagination-wrapper {
	flex-wrap: wrap;
	justify-content: center;
	display: flex;
}

.w-pagination-next-icon {
	margin-left: 4px;
}

.h5-heading {
	margin-bottom: 0;
}



.component-icon {
	width: 24px;
	height: 24px;
	min-height: 24px;
	min-width: 24px;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
	display: flex;
}
.component-icon-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.overlay-thumbnail {
	border: 2px solid #f3f5fb;
	border-radius: 8px;
}
.overlay-button-wrapper {
	grid-column-gap: 24px;
	grid-row-gap: 24px;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 32px;
	display: flex;
}

.overlay-list {
	margin-top: 10px;
}
.icon-close {
	justify-content: center;
	align-items: center;
	display: flex;
}



.overlay-detail-wrapper {
	grid-column-gap: 24px;
	grid-row-gap: 24px;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	margin-top: 32px;
	display: flex;
}
.overlay-bottom-button {
	grid-column-gap: 16px;
	display: flex;
}

.icon-regular {
	align-items: center;
	display: flex;
	justify-content: center;
}


.wrap-h-small {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-wrap: wrap;
	align-items: center;
	display: flex;
}
.f-field-icon-wrapper {
	align-items: center;
	display: flex;
	position: relative;
}


.component-applied-wrapper {
	grid-column-gap: 14px;
	grid-row-gap: 14px;
	flex-wrap: wrap;
	justify-content: flex-end;
	margin-bottom: 16px;
	padding-left: 24px;
	padding-right: 24px;
	display: flex;
}
.no-result-icon {
	width: 70px;
}
.link-block {
	justify-content: center;
	align-items: center;
	display: flex;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: 12px;
}

.title-wrapper-small {
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
    padding-top: 100px;
    position: relative;
    .item-background-gradient {
		background: var(--img-gradient-mobile) no-repeat;
        width: 100%;
        position: absolute;
        height: 100%;
        background-position: center;
        z-index: -1;
    }
	h1 {
		font-size: 24px;
		line-height: 130%;
		margin-top: 10px;
		margin-bottom: 20px;
		color: var(--color-title);
		text-align: center;
	}
	.h1-heading {
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 130%;
		color: var(--color-title);
	}
	.max-width-s {
		max-width: 520px;
		margin: auto;
		.parargraph-large {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			text-align: center;
			color: var(--color-sub);
		}
	}

}

.title-related-project{
	padding-top: 0px;
	padding-bottom: 24px;
}


#sticky-element{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    width: calc(100% + 58px);
    overflow-x: auto;
    justify-content: left;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For Internet Explorer 10+ */
	padding-left: 33px;
	padding-right: 33px;
}

#sticky-element::-webkit-scrollbar {
	display: none; /* For Chrome, Safari, and Opera */
  }

  #sticky-element.is-sticky {
	  position: fixed;
	  top: 72px; 
	  width: 100%; 
	  z-index: 9999; 
	  background: var(--background-nav) !important;
	  -webkit-backdrop-filter: blur(8px);
	  backdrop-filter: blur(8px);
	  width: calc(100% + 56px);
	  border-radius: 0px !important;
	  border: 0 !important;
	  border-top: var(--bolor-color-opacity) !important;
	  width: 100%;
  }

  
.wrap-v {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
	padding-right: 16px;
    padding-left: 16px;
    img {
        border: 0;
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
        max-width: 100%;
        display: inline-block;
    }
	.tab-menu {
		grid-column-gap: 4px;
		grid-row-gap: 4px;
		border-radius: 30px;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin-bottom: 16px;
		padding: 8px;
		display: flex;
		flex-direction: row; /* Align children horizontally */
		flex-wrap: nowrap; /* Prevents wrapping to the next line */
		a:nth-of-type(1){
			order:1;
		}
		a:nth-of-type(2){
			order:5;
		}
		a:nth-of-type(3){
			order:3;
		}
		a:nth-of-type(4){
			order:4;
		}
		a:nth-of-type(5){
			order:2;
		}
		a:nth-of-type(6){
			order:6;
			margin-right: 0px;
		}
		a:nth-of-type(7){
			order:0;
		}
	}
	.tab-link-button {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		color: var(--color-sub);
		text-align: center;
		border-radius: 50px;
		justify-content: center;
		align-items: center;
		padding: 8px 16px 8px 16px;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		transition: all 0.2s;
		display: flex;
		border: var(--bolor-color-opacity2);
		background: var(--background-color-opacity);
		margin-right: 12px;
		svg{
			margin-right: 4px;
			path{
				fill: var(--color-sub);
			}
		}
		&:hover {
			border: 2px solid #8151de;
			color: var(--color-sub);
		}
	}
	.tab-link-button.w--current {
		color: var(--color-title);
		border: 2px solid #8151de;
		background: var(--background-color-opacity);
		svg{
			margin-right: 4px;
			path{
				fill: var(--color-title);
			}
		}
	}
}

.component-filter-bar {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;
	display: flex;
}
.sub-heading-s {
	margin-top: 0;
	margin-bottom: 0;
}
.component---collection-list {
	grid-column-gap: 24px;
	grid-row-gap: 24px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
	.component-collection-card {
		border: var(--bolor-color-opacity);
		background: var(--background-color-opacity);
		border-radius: 24px;
		padding: 8px;
		position: relative;
		overflow: hidden;
		.wrap-uicustom{
			gap: 12px !important;
			.item-action-buy{
				position: absolute;
				top: 50%;
				left: 50%;
				gap: 24px;
				display: flex;
				transform: translate(-50%, -50%) scale(0);
			}
		}
		.component-content-wrap {
			z-index: 5;
			grid-row-gap: 20px;
			flex-direction: column;
			padding: 12px 8px 8px;
			display: flex;
			position: relative;
			.title-project {
				margin-bottom: 10px;
				line-height: 18px;
				color: var(--color-title);
				margin-top: 0;
				margin-bottom: 0;
				font-size: 18px;
				font-weight: 700;
				line-height: 32px;
				text-overflow: ellipsis;
				overflow: hidden;
				width: 220px;
				white-space: nowrap;
			}
		}
	}

	div.component-collection-card:hover .component-image-wrap{
		opacity: 0.5;
	}

	div.component-collection-card:hover .item-action-buy {
		-webkit-transform: translate(-50%, -120%) scale(1);
		transform: translate(-50%, -120%) scale(1);
	  }
}

.related-list{
	grid-template-columns: 1fr 1fr !important;
	.component-collection-card{
		.component-content-wrap{
			.title-project{
				width: 200px;
				margin-bottom: 10px;
				line-height: 18px;
				color: var(--color-title);
				margin-top: 0;
				margin-bottom: 0;
				font-size: 18px;
				font-weight: 700;
				line-height: 32px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}
}


.component-image-wrap {
	border-radius: 16px;
	overflow: hidden;
}


.wrap-v-x-small {
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	flex-direction: column;
	display: flex;
}
.wrap-v-x-small.align-centre {
	align-items: center;
}
.caption-m {
    color: var(--color-sub);
    font-size: 14px;
    line-height: 24px;
}
.p-badge-pro {
	color: white;
	text-align: center;
	letter-spacing: .5px;
	border-radius: 24px;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	display: flex;
	position: relative;
    &:hover{
        color:white;
    }
}
.wrap-h-between {
	width: 100%;
	justify-content: space-between;
	align-items: center;
	display: flex;
}
.component-toggle {
    width: 100%;
    height: 40px;
    color: var(--color-title);
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
	svg{
		path{
			fill:var(--color-title);
		}
	}
}
.component-dropdown {
    width: 100%;
    border-radius: 32px;
    flex: 1;
    border: var(--bolor-color-opacity);
    background: var(--background-color-opacity);
}
.component-preview {
	width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    color: var(--color-title);
    border-radius: 100px;
    justify-content: center;
    backdrop-filter: blur(8px);
    align-items: center;
    transition: background-color 0.2s;
    display: flex;
    border: var(--bolor-color-opacity);
    background: rgb(255 255 255 / 20%);
	border: 2px solid transparent;
	&:hover {
		border: 2px solid white;
	}
}

.component-wrapper {
    width: 100%;
    border-radius: 30px;
    padding: 32px 32px 44px;
    position: relative;
    border: var(--bolor-color-opacity);
    background: var(--background-color-opacity);
    margin-bottom: 160px;
	.view-load-more{
		padding-top: 60px;
		margin: 0 auto;
		display: table;
		padding-bottom: 60px;
		a{
			color: white;
			text-align: center;
			background: var(--raibow-color);
			border-radius: 32px;
			padding: 7px 16px;
			font-size: 16px;
			font-weight: 500;
			line-height: 150%;
			transition: color 0.3s, background-color 0.3s;
			&:hover{
				background: var(--raibow-color);
			}
		}
	}
	.noItem-product-uicustom{
		display: none;
	}

	#noResultMessage{
		.title-noResul{
			font-style: normal;
			font-weight: 500;
			line-height: 130%;
			color: var(--color-title);
			padding-bottom: 16px;
			font-size: 32px;
		}
		.content-noResul{
			font-style: normal;
			font-weight: 400;
			line-height: 130%;
			color: var(--color-sub);
			font-size: 16px;
		}
	}
}

.wrap-h-x-small {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-wrap: wrap;
	align-items: center;
	display: flex;
}


.caption-cateogry {
    color: var(--color-title);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    transition: color 0.3s;
	&:hover {
		color: white;
	}
}

.component-filter-dropdown {
	z-index: 9;
	svg{
		path{
			fill:var(--color-title);
		}
	}
}


.js-filter-dropdown {
	width: 100%;
}

.icon-4 {
	display: none;
}
.component-filter-collection {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-direction: column;
	align-items: stretch;
	display: flex;
}


.component-filter-search {
	margin-bottom: 0;
	position: relative;
	.f-field-icon{
		position: absolute;
		left: 12px;
	}
}


.js-component-wrap {
	padding: 8px;
}


.tab-list-link.hide {
	display: none;
}
.button-stars {
	z-index: 9;
	width: 80px;
	height: 60px;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: auto;
}

.component-filter-wrapper {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	align-items: center;
	display: flex;
}
.component-badge-wrapper {
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	flex-wrap: wrap;
	align-items: center;
	display: flex;
}
.div-block-715 {
	align-items: center;
	display: flex;
}
.component-search {
	width: 100%;
    min-height: 40px;
    border-radius: 50px;
    margin-bottom: 0;
    padding: 12px 16px 12px 44px;
    transition: background-color 0.25s;
    border: var(--bolor-color-opacity);
    background: var(--background-color-opacity);
	&:hover {
		border-color: var(--bolor-color-opacity);
	}
	&:focus {
		color: var(--color-title);
		border-color: var(--bolor-color-opacity);
	}
	&::placeholder {
		color: var(--color-sub);
	}
}

.content-wrap {
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	flex-direction: column;
	display: flex;
}


.jetboost-list-item-hide {
	display: none !important;
}

.gradient-three {
	background: linear-gradient(289.17deg, #C8BDFF -50.13%, #B5EDFF -5.03%, #00CBFF 30.57%, #6721FF 73.85%, #C8BDFF 112.65%);
	background-clip: text;
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
.button-gradient-one {
	background: linear-gradient(134deg, #FF50E3 0%, #8251DE 47.92%, #1570EF 100%);
}


@media screen and (min-width:1280px) {
	h1 {
		font-weight: 700;
	}
}
@media screen and (min-width:1440px) {
	.filter-toggle-free-component {
		min-width: 160px;
	}
}
@media screen and (min-width:1920px) {
	.overlay-toggle {
		grid-column-gap: 4px;
		align-items: center;
		display: flex;
	}
	.component-json-id {
		display: none;
	}
	.component---collection-list {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	
	.related-list{
		grid-template-columns: 1fr 1fr 1fr !important;
		.title-project{
			width: 250px !important;
		}
	}

}
@media screen and (max-width:991px) {
	.tab-menu {
		border-radius: 30px;
	}
	.tab-link-button {
		width: 49%;
	}
	.component---collection-list {
		grid-template-columns: 1fr 1fr;
	}
	.related-list{
		grid-template-columns: 1fr 1fr !important;
	}
	.component-wrapper {
		padding: 24px;
	}
}
@media screen and (max-width:767px) {
	.tab-menu {
		width: 100%;
		flex-direction: column;
	}
	.tab-link-button {
		width: 100%;
	}
	.component-filter-bar {
		flex-direction: column;
		align-items: flex-start;
	}
	.component---collection-list {
		grid-template-columns: 1fr;
	}

	.related-list{
		grid-template-columns: 1fr !important;
	}
}
@media screen and (max-width:479px) {
	h1 {
		font-size: 44px;
		line-height: 54px;
	}
	.overline {
		text-align: left;
	}
	.overlay-block {
		padding: 24px;
	}
	.jetboost-filter-reje {
		width: 100%;
	}
	.tab-menu {
		border-radius: 30px;
	}
	.tab-link-button {
		width: 100%;
		justify-content: center;
		margin-bottom: 10px;
	}
	.component-filter-bar {
		flex-direction: column;
		align-items: stretch;
	}
	.component-wrapper {
		padding: 12px;
	}
	.component-filter-dropdown {
		width: 100%;
	}
	.component-filter-collection {
		width: 100%;
		flex-direction: column;
	}
}


@media only screen and(min-width: 320px) {
    .component-filter-search{
		width: 270px;
	}
}


@media only screen and(min-width: 414px) {
    .component-filter-search{
		width: 332px;
	}
}

@media only screen and(min-width: 768px) {
	.component---collection-list{
		.component-collection-card{
			.component-content-wrap{
				.title-project{
					width: 175px;
					margin-bottom: 10px;
					line-height: 18px;
					color: var(--color-title);
					margin-top: 0;
					margin-bottom: 0;
					font-size: 18px;
					font-weight: 700;
					line-height: 32px;
					text-overflow: ellipsis;
					overflow: hidden;
					width: 220px;
					white-space: nowrap;
				}
			}
		}
	}
}


@media only screen and(min-width: 992px) {
	.title-wrapper-small{
		margin-top: 32px;
		margin-bottom: 32px;
	}
	#sticky-element {
		position: -webkit-sticky;
		position: sticky;
		top: 0; /* Đặt top là 78px */
		left:0;
		width: auto;
		flex-direction: row;
		justify-content: center;
		padding-right: 8px;
		padding-left: 8px;
	  }

	  .wrap-v{
		.tab-menu {
			grid-column-gap: 4px;
			grid-row-gap: 4px;
			border-radius: 30px;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			margin-bottom: 60px;
			padding: 8px;
			display: flex;
			flex-direction: column; /* Align children horizontally */
			flex-wrap: unset; /* Prevents wrapping to the next line */
			border: var(--bolor-color-opacity);
			background: var(--background-color-opacity);
		}
	  }

	.component---collection-list{
		grid-template-columns: 1fr 1fr;
		.component-collection-card{
			.component-content-wrap{
				.title-project{
					width: 275px;
				}
			}
		}
	}

	.title-wrapper-small {
		h1 {
			font-size: 60px;
		}
		.h1-heading {
			font-size: 60px;
		}
	}

	.component-wrapper {
		.view-load-more{
			padding-bottom: 0;
		}
	}

	.title-wrapper-small {
		.item-background-gradient {
			background: var(--img-gradient) no-repeat;
			background-position: center center;
		}
	}
	
}


@media only screen and(min-width: 1200px) {
	.component---collection-list{
		grid-template-columns: 1fr 1fr;
		.component-collection-card{
			.component-content-wrap{
				.title-project{
					width: 275px;
				}
			}
		}
	}
}

@media only screen and(min-width: 1366px) {
	.component---collection-list{
		grid-template-columns: 1fr 1fr 1fr;
		.component-collection-card{
			.component-content-wrap{
				.title-project{
					width: 300px;
				}
			}
		}
	}
	.related-list{
		grid-template-columns: 1fr 1fr 1fr !important;
		.title-project{
			width: 200px !important;
		}
	}
}

@media only screen and(min-width: 1440px) {
	.component---collection-list{
		grid-template-columns: repeat(4, minmax(0, 1fr));
		.component-collection-card{
			.component-content-wrap{
				.title-project{
					width: 260px;
				}
			}
		}
	}
}

@media only screen and(min-width: 1920px) {
	.component---collection-list{
		grid-template-columns: repeat(4, minmax(0, 1fr));
		.component-collection-card{
			.component-content-wrap{
				.title-project{
					width: 320px;
				}
			}
		}
	}
}


@media only screen and(min-width: 1440px) {
	.component---collection-list{
		.component-collection-card{
			.component-content-wrap{
				.title-project{
					width: 260px;
				}
			}
		}
	}
}


@media only screen and(min-width: 1920px) {
	.component---collection-list{
		.component-collection-card{
			.component-content-wrap{
				.title-project{
					width: 300px;
				}
			}
		}
	}
}


.project-item-list{
	display: none;
}