// Header
.info-uicustom {
    padding-top: 84px;
    position: relative;
    overflow: hidden;
    height: 510px;

    &:before {
        content: "";
        width: 100px;
        height: 755px;
        position: absolute;
        top: 0;
        background: var(--background-gradient-color-before);
        z-index: 1;
    }

    &:after {
        content: "";
        width: 100px;
        height: 755px;
        position: absolute;
        top: 0;
        right: 0;
        background: var(--background-gradient-color-after);
        z-index: 1;
    }

    .item-background-gradient {
        background: var(--img-gradient-mobile) no-repeat;
        filter: blur(128px);
        width: 100%;
        position: absolute;
        height: 100%;
        background-position: center;
    }

    .item-info-uicustom {
        position: relative;

        .column-decor {
            display: flex;
            gap: 30px;
            align-items: center;
            margin-top: -10px;
            .column {
                border-right: var(--bolor-color-opacity);
                border-left: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                width: 300px;
                height: 400px;

                &:nth-child(2) {
                    display: none;
                }

                &:nth-child(3) {
                    display: none;
                }
            }
        }
    }

    .item-info-content {
        position: absolute;
        top: 40px;
        margin: 0 auto;
        display: flex;
        flex-flow: row-reverse wrap;
        align-items: center;
        justify-content: center;
        z-index: 2;

        h1 {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            color: var(--color-title);
            padding-top: 16px;
            width: auto;
            text-align: center;
            padding-bottom: 8px;
        }

        .content-header {
            color: var(--color-sub);
            text-align: center;
            padding-bottom: 16px;
            font-size: 14px;
        }

        a {
            border-radius: 50px;
            padding: 6.5px 16px;
            background: var(--raibow-color);
            color: white;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            margin: 0 auto;
            text-align: center;
            z-index: 2;
            position: relative;
            display: table;
        }

        .img-header-follow-ui {
            background: var(--img-header-follow-ui) no-repeat;
            width: 100%;
            height: 181px;
            z-index: 2;
            background-position: top 24px center;
            background-size: contain;
        }

        .gradient-top-img {
            background: var(--background-gradient-color-bottom);
            width: 100%;
            height: 50px;
            position: absolute;
            top: 220px;
        }

    }

}

.item-content-chip {
    border-radius: 50px;
    border: var(--bolor-color-opacity);
    background: var(--background-color-opacity);
    backdrop-filter: blur(8px);
    padding: 6px 12px 4px 12px;
    display: flex;
    gap: 8px;
    align-items: center;
    display: inline-flex;

    .item-icon-ai {
        background: var(--img-icon-ai) no-repeat;
        width: 24px;
        height: 24px;
    }

    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: var(--color-title-blue-tone);
    }
}

.Info-variants-auto {
    padding-top: 80px;
    position: relative;
    padding-bottom: 32px;

    .sub-title-homepage {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        color: var(--color-title);
        padding-top: 16px;
    }

    .content-description-variants {
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: var(--color-sub);
        bottom: 24px;
        width: 100%;
    }
}


// variants

.item-variants {
    padding-bottom: 30px;

    .item-info-body {
        border-radius: 12px;
        border: var(--bolor-color-opacity);
        background: var(--background-color-opacity);
        padding: 16px;

        .item-content-detail {
            padding: 8px 8px 0px 8px;

            .header-content {
                display: flex;
                position: relative;
                padding-bottom: 20px;
                align-items: center;

                .item-icon {
                    svg {
                        width: 40px;
                        height: 40px;
                    }
                }

                .item-number {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    position: absolute;
                    right: 0;
                    color: #667085;
                }
            }

            .item-body-content {
                padding-bottom: 20px;

                .sub-title-body-content {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    color: var(--color-title);
                    padding-bottom: 8px;
                    width: 100%;
                }

                p {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    color: var(--color-sub);
                    width: 100%;
                }
            }
        }

        .item-effect-content {
            border-radius: 12px;
            background: var(--background-color-opacity);
            width: 100%;
            height: 302px;
            position: relative;
            overflow: hidden;

            // Rada
            @keyframes spin {
                to {
                    transform: rotate(1turn);
                }
            }

            @keyframes blink {

                2%,
                20% {
                    background: linear-gradient(227deg, #FF50E3 0%, #8251DE 47.92%, #1570EF 100%);
                    box-shadow: 0 0 1vmin rgba(255, 255, 255, 0.6);
                }

                90% {
                    background-color: transparent;
                }
            }

            .rank-design-rada {
                position: absolute;
                right: 65px;
                bottom: -30px;
                display: flex;
                gap: 40px;

                .item-rank-design {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 40px;

                    .item-number-rank {
                        color: var(--color-title);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        font-family: Be Vietnam Pro;
                    }
                }
            }

            .item-menber {
                img {
                    &:nth-child(1) {
                        position: absolute;
                        top: 20px;
                        left: 258px;
                        width: 55px;
                    }

                    &:nth-child(2) {
                        position: absolute;
                        top: 122px;
                        left: 12px;
                        width: 80px;
                    }

                    &:nth-child(3) {
                        position: absolute;
                        right: 132px;
                        top: 214px;
                        width: 110px;
                    }
                }
            }

            .radar {
                position: relative;
                width: 250px;
                height: 250px;
                border: 2.5vmin solid transparent;
                border-radius: 50%;
                box-sizing: border-box;
                overflow: hidden;
                margin-left: 50px;
                margin-top: 8px;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

            
                &::after {
                    content: "";
                    position: absolute;
                    inset: 0;
                    background-image: var(--background-rada);
                    border-radius: 50%;
                    border: var(--border-mixEX);
                    animation: spin 6s linear infinite;
                    z-index: -1;
                    background-color: var(--background-color-opacity);
                }

                &::before {
                    content: "";
                    width: 140px;
                    height: 140px;
                    border: var(--border-mixEX);
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: transparent;
                    z-index: -1;
                    background-color: var(--background-color-opacity);
                }
            }

            .item-logo {
                &::before {
                    content: "";
                    width: 80px;
                    height: 80px;
                    border: var(--border-mixEX);
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: var(--background-color-opacity);
                    z-index: -1;
                }
            }

            .radar__dot {
                position: absolute;
                width: 3%;
                height: 3%;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                animation: blink 2s ease-out infinite;

                &:first-of-type {
                    top: 24%;
                    left: 82%;
                    animation-delay: 0.25s;
                }

                &:nth-of-type(2) {
                    top: 80%;
                    left: 20%;
                    animation-delay: 1.25s;
                }

                &:last-of-type {
                    top: 28%;
                    left: 40%;
                    animation-delay: 1.75s;
                }
            }

            // End rada

            // List Task
            .item-list-task {
                margin-top: -60px;

                .item-task {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 80px;
                    width: fit-content;
                    margin: 24px auto;
                    padding: 12px;
                    border-radius: 8px;
                    width: 280px;
                    border: var(--bolor-color-opacity);
                    background: var(--background-color-opacity);

                    .item-content {
                        padding-left: 16px;

                        .title {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%;
                            letter-spacing: 0.08px;
                            padding-bottom: 4px;
                            color: var(--color-title);
                        }

                        .content-decs {
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                            color: var(--color-title-sub);
                        }
                    }

                    .border-blue {
                        border-left: 1px solid #1570EF;
                    }

                    .border-pink {
                        border-left: 1px solid #EE46BC;
                    }

                    .border-orange {
                        border-left: 1px solid #F79009;
                    }

                    .border-green {
                        border-left: 1px solid #12B76A;
                    }

                    .border-red {
                        border-left: 1px solid #D92D20;
                    }
                }
            }

            // End List Task
        }
    }

    #Order>.effect {
        order: 2;
    }

    #Order>.conent {
        order: 1;
    }
}

// End variants


// Level up
.mb-section-uicustom {
    padding-bottom: 80px;
}

.level-up-uicustom {
    background: var(--icon-caro);
    height: 100%;
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        background: var(--background-gradient-color-center);
        width: 80%;
        position: absolute;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::before {
        content: "";
        background: var(--background-gradient-color-bottom);
        width: 100%;
        position: absolute;
        height: 100%;
        bottom: 0;
    }


    .gradient-levelup {
        &::after {
            content: "";
            top: 200px;
            z-index: 1;
            background: var(--img-gradient) no-repeat;
            background-position: bottom;
            mix-blend-mode: var(--mix-blend-mode);
            filter: blur(128px);
            width: 40vh;
            position: absolute;
            height: 100%;
            left: -200px;
        }

        &::before {
            content: "";
            top: 200px;
            z-index: 1;
            background: var(--img-gradient) no-repeat;
            background-position: bottom;
            mix-blend-mode: var(--mix-blend-mode);
            filter: blur(128px);
            width: 40vh;
            position: absolute;
            height: 100%;
            right: -300px;
        }
    }

    .item-connect-app {
        display: flex;
        align-items: center;
        gap: 80px;
        justify-content: center;
        padding-top: 60px;
        z-index: 1;
        position: relative;

        .item-logo-level {
            position: relative;

            &:after {
                content: "";
                border: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                width: 60px;
                height: 60px;
                position: absolute;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &::before {
                content: "";
                border: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                width: 100px;
                height: 100px;
                position: absolute;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .logo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 32px;
                height: 32px;
            }

            .figma {
                width: 28px;
                height: 28px;
            }
        }

        .item-logo-connect {
            background: var(--icon-connect-follow) no-repeat;
            width: 36px;
            height: 36px;
            background-size: 36px;
            background-position: center;
        }
    }

    .item-content {
        z-index: 1;
        margin: 0 auto;
        display: table;
        position: relative;
        padding-top: 0px;

        .item-content-chip {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: 80px;
        }

        .sub-title-homepage {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            color: var(--color-title);
            text-align: center;
        }
    }

    .list-card-content-follow {
        padding-top: 15px;

        .item-card-info-level {
            position: relative;
            z-index: 1;
            text-align: center;
            border-radius: 12px;
            border: var(--bolor-color-opacity);
            background: var(--background-color-opacity);
            backdrop-filter: blur(8px);
            padding: 24px 40px;
            margin-bottom: 15px;

            .title {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                color: var(--color-title);
                padding-bottom: 8px;

                span {
                    background: var(--raibow-color);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .decs {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                color: var(--color-sub);
            }
        }
    }
}

// End Level up


// Build Solution
.build-solution {
    padding-top: 80px;
    position: relative;

    .item-title-solution {
        text-align: center;

        .sub-title-homepage {
            font-size: 24px;
            width: 100%;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            color: var(--color-title);
            padding-top: 16px;
            padding-bottom: 40px;
            margin: 0 auto;
            display: table;
            text-align: center;
        }
    }

    .item-project-delays {
        padding: 16px;
        margin-bottom: 30px;
        overflow: hidden;
        border-radius: 8px;
        border: var(--bolor-color-opacity);
        background: var(--background-color-opacity);
        backdrop-filter: var(--backdrop-filter);

        &::before {
            content: "";
            background: var(--img-gradient) no-repeat;
            mix-blend-mode: var(--mix-blend-mode);
            filter: blur(126px);
            width: 100%;
            position: absolute;
            height: 100%;
            background-position: center;
            background-size: contain;
            opacity: 0.7;
        }

        .item-calendar-timeline {
            display: flex;
            gap: 0px;
            border-radius: 8px;
            border: var(--bolor-color-opacity);
            background: var(--background-color-opacity);
            backdrop-filter: var(--backdrop-filter);
            flex-direction: column;
            padding: 16px;

            .item-calendar-solution {
                .row-color {
                    background: linear-gradient(134deg, #FF50E3 0%, #8251DE 47.92%, #1570EF 100%);
                    backdrop-filter: blur(8px);
                    margin-bottom: 16px;
                    border-radius: 4px;
                    &:nth-child(1) {
                        margin-top: 16px;
                        width: 70px;
                        height: 32px;
                        border-radius: 0px 4px 4px 0px;
                        margin-left: -16px;
                    }
                    &:nth-child(2) {
                        width: 230px;
                    }


                    &:nth-child(3) {
                        margin-left: 92px;
                        width: 230px;
                        height: 32px;
                        border-radius:4px;

                        .item-process {
                            position: absolute;
                            top: -1px;
                            left: 0;
                            height: 32px;
                            border-radius: 4px 0px 0px 4px;
                            background: linear-gradient(134deg, #FF50E3 0%, #8251DE 47.92%, #1570EF 100%);
                            -webkit-backdrop-filter: blur(8px);
                            backdrop-filter: blur(8px);
                        }
                    }

                    .title-calendar {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        color: white;
                        padding: 6px 12px;
                    }
                }

                .process-calendar{
                    border: var(--bolor-color-opacity-dash);
                    background: var(--background-color-opacity);
                    -webkit-backdrop-filter: var(--backdrop-filter);
                    backdrop-filter: var(--backdrop-filter);
                    width: 230px;
                    height: 32px;
                    position: relative;
                    border-radius: 4px;
                }
                .row-default {
                    border-radius: 4px;
                    border: var(--bolor-color-opacity-dash);
                    background: var(--background-color-opacity);
                    backdrop-filter: var(--backdrop-filter);
                    margin-bottom: 16px;

                    &:nth-child(4) {
                        width: 170px;
                        height: 32px;
                        margin-left: 40px;
                    }

                    &:nth-child(5) {
                        width: 171px;
                        height: 32px;
                        margin-left: 135px;
                    }

                    .title-default {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        color: #667085;
                        padding: 6px 12px;
                    }
                }
            }

            .item-layout-system-design {
                border-radius: 8px;
                border: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                backdrop-filter: blur(8px);
                width: 100%;
                margin-top: 20px;

                .item-title {
                    padding: 16px 12px 12px 12px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: var(--bolor-color-opacity);

                    .title {
                        color: var(--color-title);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%;
                    }

                    .item-close{
                        svg{
                            path{
                                fill: var(--color-title-sub);
                            }
                        }
                    }
                }

                .item-list-cate {
                    padding: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: var(--bolor-color-opacity);

                    .item-list {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        justify-content: space-between;

                        &:nth-child(2) {
                            gap: 0;
                        }
                    }

                    .item-text {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        color: var(--color-sub);
                        svg{
                            path{
                                fill: var(--color-title-sub);
                            }
                        }
                    }

                    .active {
                        color: var(--color-title);
                        font-weight: 500;
                    }
                }

                .item-search {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    padding: 12px;
                    border-bottom: var(--bolor-color-opacity);
                    svg{
                        path{
                            fill: var(--color-title-sub);
                        }
                    }
                    .item-text-search {
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        color: var(--color-sub);
                    }
                }

                .item-title-layout-uicustom {
                    padding: 12px 12px 0px 12px;

                    .item-title-layout {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding-bottom: 16px;

                        .orange {
                            background-color: #F79009;
                        }

                        .indigo {
                            background-color: #6172F3;
                        }

                        .dots-component {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                        }

                        .title-layout {
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                            color: var(--color-sub);
                        }
                    }
                }
            }
        }

        .item-title-project-delays {
            .title {
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                padding-bottom: 8px;
                padding-top: 20px;
                color: var(--color-title);
                text-align: center;
            }

            .text {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                color: var(--color-sub);
                margin: 0 auto;
                display: block;
                text-align: center;
            }
        }
    }

    .item-list-menber-add {
        border-radius: 12px;
        border: var(--bolor-color-opacity);
        background: var(--background-color-opacity);
        backdrop-filter: var(--backdrop-filter);
        padding: 16px;
        position: relative;
        margin-bottom: 30px;

        .item-title-list-menber {
            .title-list-menber {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                color: var(--color-title);
                text-align: center;
            }

            .item-text-list-menber {
                padding-top: 8px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                color: var(--color-sub);
                text-align: center;
                padding-bottom: 20px;
            }
        }

        .item-list-menber {
            border-radius: 8px;
            border: var(--bolor-color-opacity);
            background: var(--background-color-opacity);
            backdrop-filter: var(--backdrop-filter);
            padding: 16px 16px 0px 16px;

            &::before {
                content: "";
                position: absolute;
                width: 50%;
                height: 100%;
                border-radius: 0px 10px 10px 0px;
                background: var(--background-gradient-build-before);
                z-index: 1;
                right: 0;
                top: 0;
            }

            .item-list-input-add {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .item-text {
                    border-radius: 4px;
                    border: var(--bolor-color-opacity);
                    background: var(--background-color-opacity);
                    backdrop-filter: blur(8px);
                    padding: 10px 12px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    color: var(--color-sub);
                }

                .item-decs {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    color: var(--color-sub);
                }
            }

            .item-list-user-menber {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                margin-bottom: 16px;
                border-radius: 4px;
                border: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                backdrop-filter: blur(8px);
                padding: 8px;

                .item-info-user {
                    display: flex;
                    gap: 8px;
                    align-items: center;

                    .name {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        color: var(--color-title-sub);
                    }
                }

                .item-info-rule {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    color: var(--color-sub);
                }
            }
        }
    }

    .item-list-version-github {
        padding: 16px;
        border-radius: 12px;
        border: var(--bolor-color-opacity);
        background: var(--background-color-opacity);
        backdrop-filter: var(--backdrop-filter);
        margin-bottom: 30px;

        .item-info-save-new-version {
            border-radius: 8px;
            border: var(--bolor-color-opacity);
            background: var(--background-color-opacity);
            backdrop-filter: var(--backdrop-filter);
            padding: 16px;

            &::before {
                content: "";
                position: absolute;
                width: 50%;
                height: 100%;
                border-radius: 0px 10px 10px 0px;
                background: var(--background-gradient-build-before);
                z-index: 1;
                right: 0;
                top: 0;
            }

            .title {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                color: var(--color-title-sub);
                margin-bottom: 20px;
            }

            .item-list-decs-version {
                border-radius: 4px;
                border: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                backdrop-filter: blur(8px);
                padding: 8px 8px 0px 8px;
                margin-bottom: 16px;

                .item-text-list-version {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 12px;
                    .item-icon-tick{
                        svg{
                            path{
                                &:nth-child(1){
                                    opacity: 0;
                                }
                                &:nth-child(2){
                                    fill: var(--color-title-sub);
                                    opacity: 0.4;
                                }
                                &:nth-child(3){
                                    fill: var(--color-sub);
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    .item-content-decs {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        color: var(--color-sub);
                    }
                }
            }

            .item-decs-github {
                display: flex;
                gap: 8px;
                align-items: center;
                border-radius: 4px;
                border: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                backdrop-filter: blur(8px);
                padding: 8px;
                svg{
                    path{
                        fill:var(--color-title-sub);
                    }
                }
                .text-github {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    color: var(--color-title-sub);
                }
            }
        }

        .content-version-github {
            .title-content {
                margin-top: 20px;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                color: var(--color-title);
                padding-bottom: 8px;
                text-align: center;
            }

            .text {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                color: var(--color-sub);
                text-align: center;
            }
        }
    }

    .item-section-design-token {
        border-radius: 12px;
        border: var(--bolor-color-opacity);
        background: var(--background-color-opacity);
        backdrop-filter: var(--backdrop-filter);
        padding: 16px;
        margin-bottom: 30px;

        .item-list-design-token {
            display: flex;
            width: 100%;
            gap: 16px;
            flex-direction: column;

            .item-all-project {
                padding: 16px;
                border-radius: 8px;
                border: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                backdrop-filter: var(--backdrop-filter);
                width: 100%;
                overflow: hidden;

                &::before {
                    content: "";
                    position: absolute;
                    width: 50%;
                    height: 100%;
                    border-radius: 0px 10px 10px 0px;
                    background: var(--background-gradient-build-before);
                    z-index: 1;
                    right: 0;
                    top: 0;
                }

                .item-title-all-project {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    color: var(--color-title-sub);
                    padding-bottom: 16px;
                }

                .item-list-card-project-design {
                    display: flex;
                    gap: 16px;
                    align-items: center;
                    width: 500px;

                    .item-card-project {
                        border-radius: 8px;
                        border: var(--bolor-color-opacity);
                        background: var(--background-color-opacity);
                        backdrop-filter: var(--backdrop-filter);
                        padding-bottom: 8px;

                        .item-header-project {
                            display: flex;
                            gap: 12px;
                            align-items: center;
                            padding: 12px 8px;
                            border-bottom: var(--bolor-color-opacity);

                            .item-icon-avatar-team {
                                border: var(--bolor-color-opacity);
                                background: var(--background-color-opacity);
                                width: 46px;
                                padding: 9px;
                                border-radius: 50%;
                                svg{
                                   path{
                                        fill: var(--color-title-sub);
                                   }
                                }
                            }

                            .item-info-team {
                                .name-team {
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 150%;
                                    color: var(--color-title-sub);
                                    padding-bottom: 4px;
                                }

                                .decs-team {
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 150%;
                                    color: var(--color-sub);
                                }
                            }

                        }

                        .item-list-card-all-project {
                            padding: 8px 8px 0px 8px;

                            .item-info-content-list {
                                border-radius: 4px;
                                border: var(--bolor-color-opacity);
                                background: var(--background-color-opacity);
                                backdrop-filter: blur(8px);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 150%;
                                color: var(--color-sub);
                                padding: 8px 50px 8px 8px;
                                list-style-type: disc;
                            }
                        }
                    }
                }
            }

            .item-all-design-token {
                padding: 16px;
                border-radius: 8px;
                border: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                -webkit-backdrop-filter: blur(8px);
                backdrop-filter: blur(8px);
                width: 100%;
                overflow: hidden;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 50%;
                    border-radius: 0px 0px 10px 10px;
                    background: var(--background-gradient-build-before-bottom);
                    z-index: 1;
                    right: 0;
                    bottom: 0;
                }

                .item-header-logo {
                    width: 80px;
                    height: 80px;
                    background: var(--background-color-opacity);
                    border: var(--bolor-color-opacity);
                    padding: 20px;
                    border-radius: 50%;
                    margin: 8px auto 24px;
                    display: block;
                }

                .mlX {
                    margin-left: -82px;
                }

                .item-list-token {
                    display: flex;
                    gap: 12px;
                    margin-top: 12px;

                    .item-token {
                        display: flex;
                        gap: 8px;
                        padding: 8px;
                        align-items: center;
                        border-radius: 4px;
                        border: var(--bolor-color-opacity);
                        background: var(--background-color-opacity);
                        .icon-token{
                            svg{
                                path{
                                    fill: var(--color-title-sub);
                                }
                            }
                        }
                        .item-name-token {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                            color: var(--color-sub);
                        }
                    }
                }
            }
        }

        .item-content-design-token-main {
            padding-top: 5px;

            .title {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                padding-top: 20px;
                padding-bottom: 8px;
                color: var(--color-title);
                text-align: center;
            }

            .desc {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                color: var(--color-sub);
                text-align: center;
            }
        }
    }
}

// End Buid Solution

// Pricing
.uicustom-pricing {
    text-align: center;
    padding-top: 80px;

    .title {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        text-align: center;
        color: var(--color-title);
        padding-top: 16px;
        padding-bottom: 20px;
    }

    .item-pricing-detail-uicustom {
        border-radius: 12px;
        border: var(--bolor-color-opacity);
        background: var(--background-color-opacity);
        backdrop-filter: var(--backdrop-filter);
        padding: 24px;
        margin-bottom: 30px;

        .item-header {
            border-radius: 50px;
            border: var(--bolor-color-opacity);
            background: var(--background-color-opacity);
            backdrop-filter: var(--backdrop-filter);
            padding: 4px 12px;
            display: flex;
            align-items: center;
            gap: 8px;
            width: fit-content;

            .item-type-pricing {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                color: var(--color-title-sub);
                margin-top: 2px;
            }
        }

        .item-body-detail-pricing {
            .title-pricing {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                color: var(--color-title);
                padding: 24px 0px;
                border-bottom: var(--bolor-color-opacity);
                text-align: left;
            }

            .item-list-pricing-main {
                padding-top: 24px;
                display: flex;
                align-items: start;
                gap: 0;
                flex-direction: column;
                margin-bottom: 0px;

                .item-list-features-pricing {
                    .detail-features {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        margin-bottom: 24px;

                        .icon-features-pricing {
                            background: var(--icon-pricing) no-repeat;
                            background-size: 24px 24px;
                            width: 24px;
                            height: 24px;
                        }

                        .text-features {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                            color: var(--color-title-sub);
                        }
                    }
                }
            }

            .footer-pricing {
                padding: 8px 24px;
                width: 100%;
                text-align: center;
                border-radius: 50px;
                border: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                margin-top: 0px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                color: var(--color-title);
                display: inline-block;
            }
        }
    }

    .active-pricing {
        border: 2px solid #8151de;
        .item-body-detail-pricing {
            .footer-pricing {
                background: var(--raibow-color);
                color: white;
                border:unset;
            }
        }
    }
}

// End Pricing

// testimonials
.testimonials-card-uicustom {
    padding-top: 80px;

    @mixin dark-gradient {
        background: var(--background-gradient-color-before);
    }

    $animationSpeed: 200s;

    // Animation
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(calc(-410px * 7))
        }
    }

    @keyframes scroll2 {
        0% {
            transform: translateX(calc(-410px * 7))
        }

        100% {
            transform: translateX(0);
        }
    }

    .header-uicustom-testimonials {
        text-align: center;
        padding-bottom: 20px;

        .title {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            color: var(--color-title);
            padding-bottom: 8px;
            padding-top: 16px;
            text-align: center;
        }

        .text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            color: var(--color-sub);
            text-align: center;
        }
    }

    .item-list-card-testimonials {
        height: 100%;
        margin: auto;
        overflow: hidden;
        position: relative;
        width: 100%;
        margin-bottom: 30px;

        &::before,
        &::after {
            @include dark-gradient;
            content: "";
            height: calc(100% - 30px);
            position: absolute;
            width: 0px;
            z-index: 2;
        }

        &::after {
            right: 0;
            top: 0;
            transform: rotateZ(180deg);
        }

        &::before {
            left: 0;
            top: 0;
        }

        .slider-track-testimonials {
            animation: scroll $animationSpeed linear infinite;
            display: flex;
            width: calc(410px * 14);
            gap: 15px;
            margin-bottom: 15px;

            &:nth-child(2) {
                animation: scroll2 $animationSpeed linear infinite;
            }

            .item-card-testimonials {
                border-radius: 8px;
                border: var(--bolor-color-opacity);
                background: var(--background-color-opacity);
                padding: 16px 24px;
                width: 410px;
                height: 194px;

                .card-header-testimonials {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    padding-bottom: 16px;

                    .avatar {
                        img {
                            width: 48px;
                            height: 48px;
                            object-fit: cover;
                        }
                    }

                    .info-testimonials {
                        .name {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                            padding-bottom: 4px;
                            color: var(--color-title);
                        }

                        .tag-name {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                            color: var(--color-sub);
                        }
                    }
                }

                .body-content-testimonials {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    color: var(--color-title);
                }
            }
        }

    }
}

// End testimonials

// About Team
.about-team {
    padding-top: 80px;
    overflow: hidden;

    .header-about-team {
        text-align: center;
        position: relative;
        z-index: 8;

        .title {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            color: var(--color-title);
            padding-top: 16px;
            padding-bottom: 16px;
        }

        .text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            color: var(--color-sub);
            padding-bottom: 32px;
        }

        .contact-now-uicustom {
            border-radius: 50px;
            background: var(--raibow-color);
            padding: 8px 24px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            color: white;
        }
    }

    .item-globe-uicustom {
        .img-globe-team{
            display: block;
            height: 40vh;
        }
    }
}

// End About Team

@media only screen and(min-width: 768px) {
    .about-team {
        margin-bottom:-100px;
        .item-globe-uicustom {
            .img-globe-team{
                height: 70vh;
            }
        }
        .globe{
            display: none;
        }
    }
}
@media only screen and(min-width: 992px) {
    .img-header-follow-ui{
        height: 500px;
    }
    .info-uicustom {
        padding-top: 84px;
        height: 100vh;

        &:before {
            content: "";
            width: 735px;
            height: 755px;
            background: var(--background-gradient-color-before);
        }

        &:after {
            content: "";
            width: 735px;
            height: 755px;
            background: var(--background-gradient-color-after);
        }

        .item-background-gradient {
            background: var(--img-gradient) no-repeat;
            mix-blend-mode: var(--mix-blend-mode);
            filter: blur(128px);
            width: 100%;
            position: absolute;
            height: 100%;
            background-position: center;
        }

        .item-info-uicustom {
            .column-decor {
                .column {
                    width: 300px;
                    height: 660px;

                    &:nth-child(2) {
                        display: block;
                    }

                    &:nth-child(3) {
                        display: block;
                    }
                }
            }
        }

        .item-info-content {
            top: 70px;

            h1 {
                font-size: 60px;
                line-height: 130%;
                padding-top: 16px;
                width: 992px;
                padding-bottom: 16px;
            }

            .content-header {
                padding-bottom: 24px;
                font-size: 16px;
            }

            a {
                font-size: 18px;
            }

            .img-header-follow-ui {
                height: 1290px;
                background-position: top 40px center;
                background-size: contain;
            }

            .gradient-top-img {
                height: 129px;
                top: 244px;
            }
        }

    }

    .Info-variants-auto {
        padding-top: 130px;
        position: relative;
        padding-bottom: 32px;

        .sub-title-homepage {
            font-size: 60px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            color: var(--color-title);
            padding-top: 16px;
        }

        .content-description-variants {
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            color: var(--color-sub);
            bottom: 24px;
            width: 412px;
            position: absolute;
            right: 0;

            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
        }
    }


    .item-variants {
        padding-bottom: 30px;

        .item-info-body {
            border-radius: 12px;
            border: var(--bolor-color-opacity);
            background: var(--background-color-opacity);
            padding: 16px;

            .item-content-detail {
                padding: 24px;

                .header-content {
                    display: flex;
                    position: relative;

                    .item-icon {
                        svg {
                            width: 60px;
                            height: 60px;
                        }
                    }

                    .item-number {
                        font-size: 34px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 130%;
                        position: absolute;
                        right: 0;
                        color: var(--color-title-sub);
                    }
                }

                .item-body-content {
                    position: absolute;
                    bottom: 0;
                    padding-bottom: 0px;

                    .sub-title-body-content {
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 130%;
                        padding-bottom: 12px;
                        width: 360px;
                    }

                    p {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        width: 420px;
                    }
                }
            }

            .item-effect-content {
                border-radius: 12px;
                background: var(--background-color-opacity);
                width: 100%;
                height: 302px;
                position: relative;
                border: var(--bolor-color-opacity);
                // Rada
                @keyframes spin {
                    to {
                        transform: rotate(1turn);
                    }
                }

                @keyframes blink {

                    2%,
                    20% {
                        background: linear-gradient(227deg, #FF50E3 0%, #8251DE 47.92%, #1570EF 100%);
                        box-shadow: 0 0 1vmin rgba(255, 255, 255, 0.6);
                    }

                    90% {
                        background-color: transparent;
                    }
                }

                .rank-design-rada {
                    position: absolute;
                    right: 40px;
                    top: 80px;
                    display: unset;

                    .item-rank-design {
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 40px;

                        .item-number-rank {
                            color: var(--color-title);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                        }
                    }
                }

                .item-menber {
                    img {
                        &:nth-child(1) {
                            position: absolute;
                            top: 38px;
                            left: 276px;
                            width: fit-content;
                        }

                        &:nth-child(2) {
                            position: absolute;
                            top: 150px;
                            left: 24px;
                            width: fit-content;
                        }

                        &:nth-child(3) {
                            position: absolute;
                            right: 202px;
                            top: 226px;
                            width: fit-content;
                        }
                    }
                }

                .radar {
                    position: relative;
                    width: 250px;
                    height: 250px;
                    border: 2.5vmin solid transparent;
                    border-radius: 50%;
                    box-sizing: border-box;
                    overflow: hidden;
                    margin-left: 80px;
                    margin-top: 26px;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        inset: 0;
                        background-image: var(--background-rada);
                        border-radius: 50%;
                        border: var(--border-mixEX);
                        animation: spin 6s linear infinite;
                        z-index: -1;
                        background-color: var(--background-color-opacity);
                    }

                    &::before {
                        content: "";
                        width: 140px;
                        height: 140px;
                        border: var(--border-mixEX);
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: transparent;
                        z-index: -1;
                        background-color: var(--background-color-opacity);
                    }
                }

                .item-logo {
                    &::before {
                        content: "";
                        width: 80px;
                        height: 80px;
                        border: var(--border-mixEX);
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: var(--background-color-opacity);
                        z-index: -1;
                    }
                }

                .radar__dot {
                    position: absolute;
                    width: 3%;
                    height: 3%;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    animation: blink 6s ease-out infinite;

                    &:first-of-type {
                        top: 24%;
                        left: 82%;
                        animation-delay: 0.75s;
                    }

                    &:nth-of-type(2) {
                        top: 80%;
                        left: 20%;
                        animation-delay: 3.75s;
                    }

                    &:last-of-type {
                        top: 28%;
                        left: 40%;
                        animation-delay: 5.75s;
                    }
                }

                // End rada


                iframe {
                    width: 100vh;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }


        #Order>.effect {
            order: 1;
        }

        #Order>.conent {
            order: 2;
        }
    }

    // Level up
    .mb-section-uicustom {
        padding-bottom: 160px;
    }

    .level-up-uicustom {
        background: var(--icon-caro);
        height: 700px;
        width: 100%;

        &::after {
            content: "";
            width: 100%;
            height: 700px;
        }

        &::before {
            content: "";
            width: 100%;
            height: 214px;
        }


        .gradient-levelup {
            &::after {
                content: "";
                top: 150px;
                width: 80vh;
                left: -380px;
            }

            &::before {
                content: "";
                top: 150px;
                width: 80vh;
                right: -525px;
            }
        }

        .item-connect-app {
            gap: 200px;
            padding-top: 100px;

            .item-logo-level {
                &:after {
                    content: "";
                    width: 100px;
                    height: 100px;
                    top: 50%;
                    left: 50%;
                }

                &::before {
                    content: "";
                    width: 160px;
                    height: 160px;
                    top: 50%;
                    left: 50%;
                }

                .logo {
                    width: 50px;
                    height: 50px;
                }

                .figma {
                    width: 42px;
                    height: 42px;
                }
            }

            .item-logo-connect {
                width: 60px;
                height: 60px;
                background-size: 60px;
            }
        }

        .item-content {
            padding-top: 80px;

            .item-content-chip {
                margin-top: 40px;
            }

            .sub-title-homepage {
                font-size: 60px;
                font-weight: 500;
                line-height: 130%;
            }
        }

        .list-card-content-follow {
            padding-top: 40px;

            .item-card-info-level {
                padding: 40px;
                margin-bottom: 0px;

                .title {
                    font-size: 34px;
                }
            }
        }
    }

    // End Level up


    // build Solution



    .build-solution {
        padding-top: 160px;

        .item-title-solution {
            .sub-title-homepage {
                font-size: 60px;
                width: 737px;
            }
        }

        .item-project-delays {
            .item-calendar-timeline {
                gap: 30px;
                flex-direction: unset;
                padding: 0;

                .item-calendar-solution {
                    .row-color {
                        &:nth-child(1) {
                            margin-left: 0;
                        }

                        &:nth-child(2) {
                            margin-left: 38px;
                        }

                        &:nth-child(3) {
                            margin-left: 145px;
                        }
                    }

                    .row-default {
                        &:nth-child(4) {
                            margin-left: 90px;
                        }

                        &:nth-child(5) {
                            margin-left: 216px;
                        }

                    }
                }

                .item-layout-system-design {
                    border-radius: 8px 0px;
                    border-right: 0;
                    border-bottom: 0;
                }
            }

            .item-title-project-delays {
                .title {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                    padding-bottom: 8px;
                    padding-top: 20px;
                    text-align: center;
                }

                .text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    margin: 0 auto;
                    display: block;
                    text-align: center;
                }
            }
        }

        .item-list-menber-add {
            .item-title-list-menber {
                .title-list-menber {
                    font-size: 24px;
                }
            }
        }

        .item-list-version-github {
            .content-version-github {
                .title-content {
                    font-size: 24px;
                }
            }
        }

        .item-section-design-token {
            .item-list-design-token {
                flex-direction: unset;

                .item-all-project {
                    width: 50%;
                }

                .item-all-design-token {
                    width: 50%;

                    .mlX {
                        margin-left: -95px;
                    }
                }
            }

            .item-content-design-token-main {
                .title {
                    font-size: 24px;
                }
            }
        }
    }
   

    // End build Solution


    // Pricing
    .uicustom-pricing {
        padding-top: 160px;

        .title {
            font-size: 60px;
            padding-top: 20px;
            padding-bottom: 32px;
        }

        .item-pricing-detail-uicustom {
            padding: 32px 24px;
            margin-bottom: 0;

            .item-header {
                gap: 12px;

                .item-type-pricing {
                    margin-top: 0;
                }
            }

            .item-body-detail-pricing {
                .title-pricing {
                    font-size: 34px;
                    padding: 40px 0px;
                }

                .item-list-pricing-main {
                    flex-direction: row;
                    gap: 40px;
                    margin-bottom: 40px;
                }
            }
        }
    }

    // End Pricing




    // testimonials
    .testimonials-card-uicustom {
        padding-top: 160px;

        .header-uicustom-testimonials {
            padding-bottom: 40px;

            .title {
                font-size: 60px;
                padding-bottom: 16px;
                padding-top: 16px;
                text-align: center;
            }
        }

        .item-list-card-testimonials {

            &::before,
            &::after {
                width: 350px;
            }


            .slider-track-testimonials {
                gap: 30px;
                margin-bottom: 30px;

                .item-card-testimonials {
                    .card-header-testimonials {
                        .info-testimonials {
                            .name {
                                font-size: 18px;
                            }
                        }
                    }

                    .body-content-testimonials {
                        font-size: 16px;
                    }
                }
            }

        }
    }

    // End testimonials


    // About Team
    .about-team {
        padding-top: 160px;
        overflow: hidden;
        margin-bottom:-280px;
        .header-about-team {
            text-align: center;
            position: relative;
            z-index: 8;

            .title {
                font-family: Be Vietnam Pro;
                font-size: 60px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                padding-top: 16px;
                padding-bottom: 16px;
            }

            .text {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                padding-bottom: 32px;
            }

            .contact-now-uicustom {
                border-radius: 50px;
                background: var(--raibow-color);;
                padding: 8px 24px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }
        }

        .item-globe-uicustom {
            border-left:var(--bolor-color-opacity);
            border-right:var(--bolor-color-opacity);
            .img-globe-team{
                display: none;
            }
            &::after {
                content: "";
                position: absolute;
                background: var(--background-gradient-color-bottomEX);
                width: 100%;
                height: 700px;
                bottom: 0;
                left: 0;
                z-index: 1;
            }
        }

        .globe{
            display: block;
        }
    }

    // End About Team
}

// Header

@media only screen and(min-width: 1200px) {
    .info-uicustom{
        height: 950px;
    }

    .about-team {
        margin-bottom: -250px;
        .item-globe-uicustom {
           &::after{
            height: 600px;
           }
        }
        .globe{
            top: -3vh;
        }
    }
}

@media only screen and(min-width: 1366px) {
    .about-team {
        margin-bottom: -250px;
        .item-globe-uicustom {
           &::after{
            height: 600px;
           }
        }
        .globe{
            top: 400px;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
            width: 1920px !important;
            height: 922px !important;
        }
    }
}


@media only screen and(min-width: 1440px) {
    .about-team {
        .item-globe-uicustom {
           &::after{
            height: 700px;
           }
        }
    }
}


@media only screen and(min-width: 1600px) {
}


@media only screen and(min-width: 1600px) {
}
// Info feature
$color_1: #f4f0ff;
$color_2: #efedfd99;
$background-color_1: #ffffff3d;
$background-color_2: #030014;


@keyframes hue-rotate {
    0% {
        filter: hue-rotate(0deg);
    }

    to {
        filter: hue-rotate(360deg);
    }
}

@keyframes hue-rotate {
    0% {
        filter: hue-rotate(0deg);
    }

    to {
        filter: hue-rotate(360deg);
    }
}

.features-ui {
    margin: 40px auto 0;
    position: relative;
    width: 1154px;
    z-index: 2;
}

.features-ui-cards {
    .swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}

.features-ui-gridlines {
    display: flex;
    height: 100%;
    justify-content: space-between;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    div {
        height: 100%;
        width: 1px;

        &:nth-child(odd) {
            background: var(--border-gradient-feature-old);
        }

        &:nth-child(even) {
            background: var(--border-gradient-feature-even);
        }

        &:last-child {
            background: var(--border-gradient-feature-last);
            height: 1px;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }
    }
}

.features-ui-card-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--color-title);
    padding-bottom: 8px;
}

.features-ui-card-description {
    color: var(--color-sub);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.features-ui-card {
    overflow: hidden;
    padding: 30px;
    position: relative;

    .icon-features-1 {
        background: var(--icon-features-1) no-repeat;
        width: 100%;
        height: 48px;
        margin-bottom: 20px;
    }

    .icon-features-2 {
        background: var(--icon-features-2) no-repeat;
        width: 100%;
        height: 48px;
        margin-bottom: 20px;
    }

    .icon-features-3 {
        background: var(--icon-features-3) no-repeat;
        width: 100%;
        height: 48px;
        margin-bottom: 20px;
    }

    .icon-features-4 {
        background: var(--icon-features-4) no-repeat;
        width: 100%;
        height: 48px;
        margin-bottom: 20px;
    }

    .icon-features-5 {
        background: var(--icon-features-5) no-repeat;
        width: 100%;
        height: 48px;
        margin-bottom: 20px;
    }

    .icon-features-6 {
        background: var(--icon-features-6) no-repeat;
        width: 100%;
        height: 48px;
        margin-bottom: 20px;
    }

    .icon-features-7 {
        background: var(--icon-features-7) no-repeat;
        width: 100%;
        height: 48px;
        margin-bottom: 20px;
    }

    .icon-features-8 {
        background: var(--icon-features-8) no-repeat;
        width: 100%;
        height: 48px;
        margin-bottom: 20px;
    }

    &:after {
        background: var(--background-features-after);
        content: "";
        height: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: .45s cubic-bezier(.6, .6, 0, 1) opacity;
        width: 100%;
    }

    &:nth-child(1n+5) {
        &:after {
            background: var(--background-features-after-top);
        }
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }

    svg {
        height: 40px;
        margin-bottom: 20px;
        width: 40px;
    }

    &:before {
        background-color: var(--background-features-before);
        content: "";
        display: block;
        height: 16px;
        left: 0;
        position: absolute;
        top: 88px;
        width: 1px;
    }

    .features-ui-card-gap {
        height: 100%;
        width: 32px;
        position: absolute;
        right: 0;
        top: 0;
        background: var(--bg-ui-card-gap);
    }
}

.features-ui-card-lines {
    display: none;

    &:before {
        content: "";
        width: 1px;
        height: 100%;
        top: 0;
        position: absolute;
        left: 0;
        background: var(--bg-card-line-features-before);
    }

    &:after {
        content: "";
        width: 1px;
        height: 100%;
        top: 0;
        position: absolute;
        right: 0;
        background: var(--bg-card-line-features-after);
    }
}



@media (max-width: 1248px) {

    .features-ui {
        width: 100%;
        // overflow: scroll;

    }

    .features-ui-cards {
        .swiper-wrapper {
            display: flex;
            grid-template-columns: unset;
        }
    }

    .features-ui-gridlines {
        div {
            &:nth-child(n+3) {
                display: none;
            }

            &:nth-child(1) {
                width: 100%;
                height: 8px;
                left: 0;
                position: absolute;
                background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .04) 100%);
                top: -8px;

                &:before {
                    content: "";
                    height: 1px;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .04) 100%);
                    bottom: 0;
                }
            }

            &:nth-child(2) {
                width: 100%;
                height: 8px;
                left: 0;
                position: absolute;
                background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .04) 100%);
                bottom: -8px;

                &:before {
                    content: "";
                    height: 1px;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .04) 100%);
                    top: 0;
                }
            }
        }
    }

    .features-ui-card {
        width: 322px;
        max-width: 322px;
        min-width: 322px;
        padding-right: 48px;
        height: 196px;
        background-color: var(--lightBg);

        &:after {
            content: unset;
        }
    }

    .features-ui-card-lines {
        display: block;

        &:after {
            right: 32px;
        }
    }
}

@media (min-width: 320px) {
    .features-ui-card {
        max-width: 100%;
    }
}

@media (min-width: 414px) {
    .features-ui-card {
        max-width: 100%;
    }
}



@media (min-width: 768px) {
    .features-ui-card {
        max-width: 322px;
    }
}


@media (min-width: 992px) {
    .features-ui-card {
        max-width: 322px;
    }
}

@media (min-width: 1248px) {
    .features-ui-card-gap {
        display: none;
    }
}



// End 123123123
