$color_1: transparent;
$color_2: #160042;
$color_3: #6b7194;
$color_4: #642eff;
$color_5: #1b114a;
$color_6: #fff;
$background-color_1: transparent;
$background-color_2: #f3f5fb;
$background-color_3: #f7f8fd;
$background-color_4: #f7f5ff;
$background-color_5: #160042;
$background-color_6: #1b114a;
$background-color_7: #642eff;


figure {
	display: block;
	margin: 1em 40px;
	margin: 0 0 10px;
}
header {
	display: block;
	padding-top: 160px;
}




h4 {
    color: var(--color-title);
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24x;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    padding-bottom: 12px;
}
h5 {
    color: var(--color-title);
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    padding-bottom: 12px;
}
p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--color-sub);
}
.w-richtext {
	&:before {
		content: " ";
		grid-area: 1/1/2/2;
		display: table;
	}
	&:after {
		content: " ";
		grid-area: 1/1/2/2;
		display: table;
		clear: both;
	}
	figure {
		max-width: 60%;
		position: relative;
		>div {
			&:before {
				cursor: default !important;
			}
		}
		img {
			width: 100%;
		}
		div {
			color: $color_1;
			font-size: 0;
		}
	}
	figure.w-richtext-figure-type-image {
		display: table;
		>div {
			display: inline-block;
		}
	}
	figure.w-richtext-align-fullwidth {
		width: 100%;
		max-width: 100%;
		text-align: center;
		clear: both;
		margin-left: auto;
		margin-right: auto;
		display: block;
		>div {
			padding-bottom: inherit;
			display: inline-block;
		}
	}
}
.rich-text-block {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 40px;
	hr{
		border-top: var(--bolor-color-opacity);
	}
	p {
	    color: var(--color-title);
		margin-top: 0;
		margin-bottom: 16px;
		font-size: 18px;
		line-height: 170%;
		font-weight: 200;
		letter-spacing: 0.5;
	}
	strong {
		color: var(--color-title);
	}
	img {
		border: 1px solid rgba(155,158,184,.15);
		border-radius: 10px;
		margin-bottom: 8px;
		box-shadow: 0 20px 40px rgba(0,0,0,.04);
	}
	h4 {
		margin-top: 24px;
		margin-bottom: 16px;
		font-size: 28px;
	}
	h5 {
		margin-bottom: 16px;
		font-size: 28px;
		line-height: 36px;
	}
	a {
		color: #1570EF !important;
		text-decoration: none;
		&:active {
			outline: 0;
		}
		&:hover {
			outline: 0;
		}
	}
}
.parargraph-large {
	font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--color-sub);
    text-align: left;
}
.blog-line-break {
	width: 80%;
	height: 1px;
    border-bottom: var(--bolor-color-opacity);
	margin: 40px auto 32px;
}
.badge-date {
	color: var(--color-sub);
	letter-spacing: .06em;
	text-transform: uppercase;
    background: var(--background-color-opacity);
    border: var(--bolor-color-opacity);
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	padding: 4px 16px;
	font-size: 12px;
	font-weight: 700;
	line-height: 24px;
	display: flex;
}
.badge-category {
	color: var(--color-title);
	letter-spacing: .06em;
	text-transform: uppercase;
	border-radius: 100px;
	align-items: center;
	padding: 4px 16px;
	font-size: 12px;
	font-weight: 700;
	line-height: 24px;
	display: flex;
	background: var(--background-color-opacity);
    border: var(--bolor-color-opacity);
}
.blog-back-button {
	max-width: 180px;
	color: var(--color-title);
	letter-spacing: -.02em;
	align-items: center;
	margin-bottom: 16px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 24px;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-decoration: none;
	display: flex;
}
.blog-header-block {
	max-width: 740px;
	text-align: left;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	position: relative;
	.item-background-gradient{
		background: var(--img-gradient-mobile) no-repeat;
		width: 100%;
		position: absolute;
		height: 100%;
		background-position: top center;
		z-index: -1;
		top: 0;
		left: 0;
	}
	img {
		border: 0;
		vertical-align: middle;
		display: inline-block !important;
		width: 100% !important;
		height: auto !important;
	}

	a{
		color:var(--color-title);
	}

	strong {
		font-weight: 700;
		font-weight: 700;
	}
}
.div-block-545 {
	width: 32px;
	height: 32px;
	min-height: 32px;
	min-width: 32px;
	background-color: $background-color_2;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
	display: flex;
}
.blog-project-buttons {
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	color: var(--color-title);
	text-align: center;
    background: var(--background-color-opacity);
    border: var(--bolor-color-opacity);
	border-radius: 32px;
	justify-content: center;
	align-items: center;
	padding: 8px 16px 8px 12px;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	transition: color .4s,background-color .4s;
	display: flex;
	&:hover {
		color: var(--color-title);
		background-color: #1570EF;
	}
	&:active {
		background-color: #175CD3;
	}
	&:focus {
		color: $color_6;
		background-color: #1849A9;
	}
}
.blog-header-thumbnail {
	width: 100%;
	border: 1px solid #f3f5fb;
	border-radius: 24px 4px 4px;
	margin-bottom: 24px;
}
.author-block {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	text-align: left;
	align-items: center;
	margin-top: 16px;
	display: none;
}
.author-avatar {
	width: 64px;
	height: 64px;
	background-color: $background-color_2;
	border-radius: 100px;
}
.parargraph-regular {
	color: $color_3;
	margin-bottom: 0;
	font-size: 14px;
	line-height: 24px;
}
.author-name-l {
	color: $color_2;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}
.author-avatar-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.blog-h1 {
	font-size: 48px;
	line-height: 1.2;
}
.blog-button-group {
	grid-column-gap: 4px;
	grid-row-gap: 4px;
    background: var(--background-color-opacity);
    border: var(--bolor-color-opacity);
	border-radius: 300px;
	align-items: center;
	padding: 8px;
	display: flex;
	svg{
		path{
			fill: var(--color-title);
		}
	}
}
.wrap-v-regular {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-direction: column;
	display: flex;
	h1 {
		color: var(--color-title);
		margin-top: 0;
		margin-bottom: 0;
		font-size: 48px;
		font-style: normal;
		font-weight: 500;
		line-height: 130%;
		padding-bottom: 10px;
	}
	
}
.wrap-v-regular.align-centre {
	align-items: center;
}
.blog-header-wrap {
	max-width: 600px;
	grid-row-gap: 24px;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	a{
		color:var(--color-title);
	}

}
.blog-badge-wrapper {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	align-items: center;
	display: flex;
	.w-inline-block {
		max-width: 100%;
		display: inline-block;
	}
	a{
		color:var(--color-sub);
		&:hover{
			color:var(--color-sub);
		}
	}
}

@media screen and (max-width:767px) {
	.rich-text-block {
		max-width: 100%;
	}
}
@media screen and (max-width:479px) {
	h1 {
		font-size: 44px;
		line-height: 54px;
	}
	.badge-date {
		display: none;
	}
	.blog-project-buttons {
		padding-left: 12px;
		padding-right: 12px;
		font-size: 14px;
	}
}


@media only screen and(min-width: 992px) {
	.blog-header-block {
		.item-background-gradient{
			background: var(--img-gradient) no-repeat;
			mix-blend-mode: var(--mix-blend-mode);
			filter: blur(128px);
		}
	}
}
