

// Body
$body-bg: #fff;

$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;


:root {
  --breakpoint-xxs: 320px;
  --breakpoint-xs: 568px;
  --breakpoint-sm: 667px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440px;
  --breakpoint-xxxl: 1600px;
}

$grid-breakpoints: (
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
) !default;


$container-max-widths: (
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
) !default;




// Typography
$font-family-sans-serif: 'Be Vietnam Pro', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$white: #FFFFFF;

$primay-body:#FFF7F7;

$Primary-colors-900:#42307D;
$Primary-colors-800:#53389E;
$Primary-colors-700:#6941C6;
$Primary-colors-600:#7F56D9;
$Primary-colors-500:#9E77ED;
$Primary-colors-400:#B692F6;
$Primary-colors-300:#D6BBFB;
$Primary-colors-200:#E9D7FE;
$Primary-colors-100:#F4EBFF;
$Primary-colors-50:#F9F5FF;


$neutral-colors-900:#101828;
$neutral-colors-800:#1D2939;
$neutral-colors-700:#344054;
$neutral-colors-600:#475467;
$neutral-colors-500:#667085;
$neutral-colors-400:#98A2B3;
$neutral-colors-300:#D0D5DD;
$neutral-colors-200:#E4E7EC;
$neutral-colors-100:#F2F4F7;
$neutral-colors-50:#F9FAFB;


$Accent-Blue-colors-900:#194185;
$Accent-Blue-colors-800:#1849A9;
$Accent-Blue-colors-700:#175CD3;
$Accent-Blue-colors-600:#1570EF;
$Accent-Blue-colors-500:#2E90FA;
$Accent-Blue-colors-400:#53B1FD;
$Accent-Blue-colors-300:#84CAFF;
$Accent-Blue-colors-200:#B2DDFF;
$Accent-Blue-colors-100:#D1E9FF;
$Accent-Blue-colors-50:#EFF8FF;



$Accent-Pink-colors-900:#841651;
$Accent-Pink-colors-800:#9E165F;
$Accent-Pink-colors-700:#C11574;
$Accent-Pink-colors-600:#DD2590;
$Accent-Pink-colors-500:#EE46BC;
$Accent-Pink-colors-400:#F670C7;
$Accent-Pink-colors-300:#FAA7E0;
$Accent-Pink-colors-200:#FCCEEE;
$Accent-Pink-colors-100:#FCE7F6;
$Accent-Pink-colors-50:#FDF2FA;


$Accent-Indigo-colors-900:#2D3282;
$Accent-Indigo-colors-800:#2D31A6;
$Accent-Indigo-colors-700:#3538CD;
$Accent-Indigo-colors-600:#444CE7;
$Accent-Indigo-colors-500:#6172F3;
$Accent-Indigo-colors-400:#8098F9;
$Accent-Indigo-colors-300:#A4BCFD;
$Accent-Indigo-colors-200:#C7D7FE;
$Accent-Indigo-colors-100:#E0EAFF;
$Accent-Indigo-colors-50:#EEF4FF;


$Semantic-Error-colors-900:#7A271A;
$Semantic-Error-colors-800:#912018;
$Semantic-Error-colors-700:#B32318;
$Semantic-Error-colors-600:#D92D20;
$Semantic-Error-colors-500:#F04438;
$Semantic-Error-colors-400:#F97066;
$Semantic-Error-colors-300:#FDA29B;
$Semantic-Error-colors-200:#FECDCA;
$Semantic-Error-colors-100:#FEE4E2;
$Semantic-Error-colors-50:#FEF3F2;


$Semantic-Warning-colors-900:#792E0D;
$Semantic-Warning-colors-800:#93370D;
$Semantic-Warning-colors-700:#B54708;
$Semantic-Warning-colors-600:#DC6803;
$Semantic-Warning-colors-500:#F79009;
$Semantic-Warning-colors-400:#FDB022;
$Semantic-Warning-colors-300:#FEC84B;
$Semantic-Warning-colors-200:#FEDF89;
$Semantic-Warning-colors-100:#FEEFC7;
$Semantic-Warning-colors-50:#FFFAEB;

$Semantic-Success-colors-900:#054F31;
$Semantic-Success-colors-800:#05603A;
$Semantic-Success-colors-700:#027948;
$Semantic-Success-colors-600:#039855;
$Semantic-Success-colors-500:#12B76A;
$Semantic-Success-colors-400:#32D583;
$Semantic-Success-colors-300:#6CE9A6;
$Semantic-Success-colors-200:#A6F4C5;
$Semantic-Success-colors-100:#D1FADF;
$Semantic-Success-colors-50:#ECFDF3;




