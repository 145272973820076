
@import '../_variables.scss';


// CSS Foolow
  
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.col-xs-6{
    width: 50%;
  }

body,
html {
    box-sizing: border-box;
    font-family: 'Be Vietnam Pro', sans-serif;
    height: auto;
    width: auto;
    min-height: 100%;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background: transparent;
    overflow-x: hidden;
}


.text-update-compoent-dui{
    p{
        color: var(--colorTitleText);
        span{
            color: var(--SubText);
        }
    }
}

.item-next-pre-component{
    padding-bottom: 80px;
    padding-top: 32px;
    .item-link-compoent-dui{
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        .item-link{
            a{
                display: flex;
                align-items: center;
                align-content: center;
                .text,
                i{
                    margin-right: 8px;
                }

            }
        }
    }

}

.item-option-system-dui {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    svg {
        display: none;
    }

    .lang-nav-dui {
        margin-top: -20px;
        bottom: 16px;
        position: absolute;
        z-index: 1;
    }

    .change-mode-dui {
        position: absolute;
        bottom: 8px;
    }
}

.body-content-componet-center-dui {
    width: 100%;
    padding-right: 0%;
    margin: 0 auto;
    .item-option-system-dui {
        display: none;
        position: fixed;
        bottom: 0;
        left: 228px;
        z-index: 1;
        border: 0;
        border-radius: 0 25px 0 0;
        outline: none;
        width: 140px;
        height: 50px;
        background: var(--background-color-opacity);
        backdrop-filter: blur(8px);
        .lang-nav-dui {
            bottom: 8px !important;
        }


        .item-decor-slider-dui {
            svg {
                transform: rotate(180deg);
                bottom: -36px;
                width: 40px;
                height: 40px;
                top: -38px;
                left: -2px;
                position: absolute;
                fill:var(--background-color-opacity);
                display: block;
            }
        }

        .change-mode-dui {
            position: absolute;
            bottom: 0 !important;
        }
    }

}

.item-option-system-dui {
    .lang-nav-dui {
        margin-top: 12px;
        margin-left: 16px;

        .lang {
            border: none;
            z-index: 1;
        }
    }

    .change-mode-dui {
        input[type=checkbox] {
            height: 0;
            width: 0;
            visibility: hidden;
        }

        label {
            cursor: pointer;
            text-indent: -9999px;
            width: 55px;
            height: 30px;
            border: var(--bolor-color-opacity);
            background: var(--background-color-opacity);
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            border-radius: 100px;
            position: absolute;
            bottom: 8px;
            left: 68px;
            backdrop-filter: blur(8px);
        }

        label:after {
            content: '';
            background: var(--color-title);
            width: 20px;
            height: 20px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            position: absolute;
            top: 4px;
            left: 4px;
            transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 320ms;
        }

        input:checked+label {
            background: var(--background-color-opacity);
            backdrop-filter: blur(8px);
        }

        input:checked+label:after {
            left: calc(100% - 5px);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
            transform: translateX(-100%);
        }
    }
}


html,
body>.toastDM {
    overflow-x: hidden;
}

html,
body>.snackbarDM {
    overflow-x: hidden;
}


a:hover {
    text-decoration: none;
    color: auto;
}


#slide-mobile-component {
    .nav {
        flex-direction: column;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Be Vietnam Pro', sans-serif;
    text-align: left;
}

p {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--colorTitleText);
    text-align: left;
    margin-bottom: 0;
}

*:focus {
    outline: none !important;
}

ol,
ul,
dl {
    margin-bottom: 0;
}


@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-40px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-40px);
    }
}

@-webkit-keyframes mover2 {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

@keyframes mover2 {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}


@-webkit-keyframes push-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
    }

    70% {
        opacity: 1;
        -webkit-transform: translateY(10%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes push-in {
    0% {
        opacity: 0;
        -moz-transform: translateY(-100%);
    }

    60% {
        opacity: 1;
        -moz-transform: translateY(10%);
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@keyframes push-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(10%);
        -moz-transform: translateY(10%);
        -ms-transform: translateY(10%);
        -o-transform: translateY(10%);
        transform: translateY(10%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes push-out {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }

    60% {
        opacity: 0;
        -webkit-transform: translateY(110%);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(100%);
    }
}

@-moz-keyframes push-out {
    0% {
        opacity: 1;
        -moz-transform: translateY(0);
    }

    60% {
        opacity: 0;
        -moz-transform: translateY(110%);
    }

    100% {
        opacity: 0;
        -moz-transform: translateY(100%);
    }
}

@keyframes push-out {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    60% {
        opacity: 0;
        -webkit-transform: translateY(110%);
        -moz-transform: translateY(110%);
        -ms-transform: translateY(110%);
        -o-transform: translateY(110%);
        transform: translateY(110%);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
    }
}

.title-slide-nav {
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: var(--colorTitleText);
    margin-bottom: 16px;
}

.version {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: $Accent-Blue-colors-600;
    padding-right: 15px;
    margin-top: -38px;
}


.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: var(--colorTitleText);
    padding-bottom: 16px;
}

.title-folfer {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: var(--colorTitleText);
    padding-bottom: 16px;
    padding-top: 8px;
}

.nav a {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--SubText);
}



.title-folder-component {
    font-weight: 500;
    font-size: 28px;
    line-height: 130%;
    color: var(--colorTitleText);
    padding-bottom: 8px;
    padding: 0px 0px 8px 0px;
}

#header-compont-dui {
    position: -webkit-sticky;
    position: fixed;
    top: 74px;
    z-index: 9;
    padding-top: 24px;
    padding-bottom: 20px;
    width: 100%;
    padding-left: 12px;
    left: 0;
}

.myObserver {
    padding-top: 160px;
}

.sticky-element {
    background: var(--lightBg);
    font-size: 24px;
    padding: 20px 16px;
    transition: 200ms;
}



.text-foloder-component {
    font-size: 14px;
    line-height: 150%;
    color: var(--SubText);
    padding-bottom: 8px;
}

.list-chip-component {
    margin-right: 8px;
    margin-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-flow: wrap;

    .item-chip {
        padding: 3px 10px;
        background: var(--activeChip);
        border-radius: 50px;
        margin-right: 8px;
        margin-bottom: 8px;

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: var(--colorTitleText);
            position: relative;
            top: -2px;
        }

        img {
            padding-right: 6px;
            margin-top: -6px;
        }
    }
}



.title-content-component {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: var(--colorTitleText);
    padding-bottom: 8px;
}

.decs-content-component {
    font-size: 14px;
    line-height: 150%;
    color: var(--SubText);
    padding-bottom: 8px;
}

.item-background-component-folder {
    padding: 16px 24px;
    margin: 16px auto;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 48px;
    backdrop-filter: blur(20px);
    border: var(--bolor-color-opacity);
    background: var(--background-color-opacity);
}

:not(pre)>code[class*=language-],
pre[class*=language-] {
    background: var(--bgCode) !important;
    border: var(--bolor-color-opacity);
    border-radius: 20px;
}


.sticky-top {
    position: unset;
    top: 0;
    z-index: 0;
}

/* *************************************************************** */




// section product

.section-product {
    height: 74vh;
    margin: 0;
    position: relative;
    top: 30px;
    left: 0px;
    background: #FFF7F7;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}




// End section product


// Quot
.quot-component {
    padding-top: 16px;
}

// End Quot





// Copy data html


.copy-click {
    position: relative;
    text-decoration: none;
    cursor: pointer;

    &:after {
        content: attr(data-tooltip-text);
        position: absolute;
        bottom: calc(100% + 6px);
        left: 50%;
        white-space: nowrap;
        background-color: white;
        color: black;
        box-shadow: 0 0 0 -12px rgba(0, 0, 0, 0);
        pointer-events: none;
        backface-visibility: hidden;
        opacity: 0;
        padding: 8px;
        font-size: 12px;
        line-height: 20px;
        transform: translate(-50%, 12px);
        //   transition: 
        //     box-shadow calc(var(--duration) / 1.5) var(--bounce),
        //     opacity calc(var(--duration) / 1.5) var(--bounce),
        //     transform calc(var(--duration) / 1.5) var(--bounce);
    }

    &.is-hovered {
        &:after {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.10);
            opacity: 1;
            transform: translate(-50%, 0%);
            transition-timing-function: var(--ease);
            color: black;
        }
    }

    &.is-copied {
        &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z' fill='%23039855'/%3E%3Cpath d='M10.5775 15.5835C10.3775 15.5835 10.1875 15.5035 10.0475 15.3635L7.2175 12.5335C6.9275 12.2435 6.9275 11.7635 7.2175 11.4735C7.5075 11.1835 7.9875 11.1835 8.2775 11.4735L10.5775 13.7735L15.7175 8.63352C16.0075 8.34352 16.4875 8.34352 16.7775 8.63352C17.0675 8.92352 17.0675 9.40352 16.7775 9.69352L11.1075 15.3635C10.9675 15.5035 10.7775 15.5835 10.5775 15.5835Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            content: attr(data-tooltip-text-copied);
            color: black;
            padding-left: 36px;
            background-position: 8px 5px;
        }
    }
}

// End Copy data html

@media (max-width: 320px) {
    .item-cardDM-task-horizontal {
        .item-content {
            .content-card {
                flex-direction: column;
                align-items: flex-start;

                .item-team {
                    margin-bottom: 24px;
                }
            }

            .footer {
                flex-direction: column;
                align-items: flex-start;

                .item-team-menber {
                    margin-bottom: 16px;
                }
            }
        }
    }
}

// Tool color
.iframeColor {
    width: 100%;
    height: 122vh
}

// End Tool color




.item-radio-resize-padding {
    label {
        float: left;
        width: 16%;
        margin-bottom: 16px;
        margin-right: 28px;
    }
}

.item-radio-resize-margin {
    label {
        float: left;
        width: 16%;
        margin-bottom: 16px;
        margin-right: 28px;
    }
}

// Search component







@media only screen and (min-width: 768px) {
    .slide-nav-left {
        position: fixed;
        top: 116px;
        right: 0;
        z-index: 9;
        width: 220px;
    }

    .myObserver {
        padding-top: 140px;
    }

    #header-compont-dui {
        padding-top: 40px;
        top: 48px;
        left: unset;
        padding-left: 0;
    }

    .info-component {

        .item-img-info-component3,
        .item-img-info-component2,
        .item-img-info-component {
            padding-bottom: 50%;
            background-position: center;
        }
    }

    .feedback-dui {
        .wrapper {
            .slick-initialized {
                .slick-slide {
                    height: 260px;
                }
            }
        }
    }

}

.contact-main-follow-ui{
    width: 100%;
    margin: 0 auto;
}
@media only screen and(min-width: 992px) {
    .contact-main-follow-ui{
        width: 60%;
    }
    html,body{
        overflow-x: unset;
    }

  // Tool color
.iframeColor {
    width: 100%;
    margin-bottom: -60px;
    height: 218px;
}

// End Tool color

    .container {
        width: 970px;
    }

    .item-AppBarDM {
        .nav-AppBarDM-Center,
        .nav-AppBarDM-Left {
            li:last-child {
                padding-left: 15px;
                position: absolute;
                right: 0;
            }
        }
    }

    .item-radio-resize-padding {
        label {
            margin-right: 22.5px;
            margin-left: 12px;
            margin-bottom: 16px;
            width: 4%;
            float: unset;
        }
    }

    .item-radio-resize-margin {
        label {
            margin-right: 24px;
            margin-left: 12px;
            margin-bottom: 16px;
            width: 4%;
            float: unset;
        }
    }


    .item-cardDM-task-horizontal {
        .item-content {
            .content-card {
                .item-team {
                    padding-right: 12px;
                }
            }
        }
    }

    .body-content-component {
        margin-top: 84px;
    }

    .title-folder-component {
        padding: 0px 0px 8px 0px;
        margin-top: 120px;
    }


    .title-slide-nav-content {
        margin-top: 84px;
    }

    .item-AppBarDM {
        .navbar {
            display: grid;
            grid-template-columns: 1fr 3fr;
            align-items: center;
            height: 50px;
            overflow: hidden;
            z-index: 1;

            ul {
                justify-self: start;

                li {
                    padding-top: 8px;
                }
            }
        }

        .Acricleenter,
        .AppLeft {
            height: 56px;
        }

        .AppLeft {
            grid-template-columns: 0.2fr 3fr;
            height: 56px;
        }

        .AppCenter {
            grid-template-columns: 1fr 3fr;
            height: 56px;
        }


        .nav-AppBar-Center,
        .nav-AppBar-Left {
            .item-form-search-appBarDM {
                position: absolute;
                right: 15px;

                button {
                    width: 100%;
                }

                .item-Textfiel {
                    margin-top: -6px;
                }
            }
        }

        .Acricleenter {
            grid-template-columns: 0fr 3fr;

            ul {
                justify-self: center;
            }
        }
    }


    .version {
        margin-top: 6px;
        text-align: left;
        margin-bottom: 12px;
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: var(--color-title);
    }

    ::-webkit-scrollbar-corner{
        background: var(--color-title) !important;
    }

    .reposiveTabDM {
        overflow: auto;
    }

    .tabsDM {

        display: inline-flex;
    }

    .tabsDM-Background {
        display: inline-flex;
    }

    #table-dataDM {
        width: 100%;
    }

    #table-dataDM_filter {
        margin-top: 0;
        text-align: right;
    }

    .ButtonBordeIcon {
        display: inline;
    }

    .ButtonBackground {
        padding: 10px 16px;
    }

    .sticky-top {
        position: -webkit-sticky;
        position: fixed;
        z-index: 9;
    }


    .list-chip-component {
        display: flex;
        margin-right: 8px;
        margin-top: 16px;
        padding-bottom: 16px;

        .item-chip {
            padding: 3px 10px;
            border: var(--bolor-color-opacity);
            background: var(--background-color-opacity);
            backdrop-filter: blur(8px);
            border-radius: 50px;
            margin-right: 8px;

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: var(--colorTitleText);
                position: relative;
                top: 0px;
            }

            img{
                padding-right: 6px;
                margin-top: -3px;
            }

            svg{
                padding-right: 6px;
                margin-top: -3px;
                path{
                    fill: var(--color-title);
                }
            }
        }
    }

    .item-background-component-folder {
        width: auto;
    }

    .body-content-componet-center-dui{
        width: 100%;
    }

    .slide-nav-right {
        overflow-y: scroll;
        height: 100vh;
        overflow-x: hidden;
        padding-top: 96px;
        top: 0vh;
        left: 0;
        padding-left: 12px;
        z-index: 10;
        background: var(--background-color-opacity);
        backdrop-filter: blur(8px);
        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
            -webkit-border-radius: 10px;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: transparent;
        }

        .nav {
            top: 0vh;
            position: relative;
        }

        a:hover {
            color: $Accent-Blue-colors-600;
        }
    }



    .myObserver{
        padding-top: 180px;
    }

    #header-compont-dui{
        top: -52px;
    }

    .body-content-componet-center-dui{
        .item-option-system-dui{
            display: block;
            left: 140px;
        }
    }

    .title-breadcrumbsDM {
        display: flex;

        .title {
            font-weight: 500;
            font-size: 24px;
            line-height: 130%;
            text-align: left;
            color: var(--colorTitleText);
            padding-right: 16px;
            margin-top: -4px;
        }

        .tags-breadcrumbsDM {
            background: #ECFDF3;
            border-radius: 50px;
            padding: 4px 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #039855;
        }
    }

    .item-infoMoreBreadcrumbsDM {
        display: flex;
    }

    .item-CardDM-Vertical {
        align-items: inherit;
        display: flex;
        height: 100%;
    }

    .item-CardDM-Vertical {
        .img-card {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 45% 55%;
            }

        }

        .item-cardDM-contact-horizontal .item-info-follow {
            display: flex;
            justify-content: space-between;
        }

        .item-cardDM-contact-horizontal .item-info-follow .item-info::after {
            content: "";
            display: inline-block;
            border-radius: 0.375rem;
            width: 5px;
            height: 5px;
            background: var(--colorTitleText);
            position: absolute;
            left: calc(100% + 28px);
            top: 8px;
        }

        .item-cardDM-contact-horizontal {
            padding: 16px;
        }

        .item-CardDM-Vertical .content-card {
            padding: 16px 16px 16px 16px;
        }

        .table {
            width: 100%;
            overflow-x: hidden;
        }


        .body-content-componet-center-dui {
            .item-option-system-dui {
                display: block;
                left: 178px;
            }
        }

        .myObserver {
            padding-top: 180px;
        }

        #header-compont-dui {
            padding-top: 30px;
            top: -34px;
        }

    }
}

@media only screen and (min-width: 1024px) {
    .item-cardDM-contact-horizontal {
        .item-info-follow {
            .item-info::after {
                left: calc(100% + 20px);
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        width: 1170px;
    }

    .slide-nav-left {
        width: 180px;
    }
}

@media only screen and (min-width: 1366px) {

    .slide-nav-left {
        width: 220px;
    }

    .body-content-componet-center-dui{
        width: 100%;
        padding-left: 80px;
    }

    .item-patreon-sponsor {
        padding-top: 250px;
        padding-bottom: 130px;

        .title {
            font-size: 24px;
        }
    }

    .info-component {
        padding-bottom: 80px;

        .item-content-info-component {
            h2 {
                font-size: 32px;
            }
        }
    }

    .list-lib {
        padding-top: 0;
        padding-bottom: 80px;
    }

    .feedback-dui {
        padding-bottom: 0px;
        padding-top: 0;
    }



    .item-radio-resize-margin,
    .item-radio-resize-padding {
        label {
            margin-right: 50px;
            margin-left: 5px;
            width: 6%;
        }
    }


    .item-cardDM-contact-horizontal {
        .item-info-follow {
            .item-info::after {
                left: calc(100% + 36px);
            }
        }
    }

    .item-AppBarDM {
        .AppLeft {
            grid-template-columns: 0.3fr 3fr;
        }

        .AppCenter {
            grid-template-columns: 1fr 3.5fr 0fr;
        }
    }

    .section-product {
        top: -4vh;
        height: 76vh;
    }


    .title-slide-nav {
        margin-bottom: 6px;
        margin-top: 5px;
        font-size: 18px;
    }

    .label {
        padding-bottom: 0px;
        margin-top: 0px;
    }

    .item-CardDM-Vertical {
        .img-card {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media only screen and (min-width: 1440px) {

    .slide-nav-right{
        width: 14%;
    }



    .item-cardDM-contact-horizontal {
        .item-info-follow {
            .item-info::after {
                left: calc(100% + 38px);
            }
        }
    }

    .body-content-componet-center-dui{
        .item-option-system-dui{
            left: 200px;
        }
    }
}

@media only screen and (min-width: 1600px) {

    .item-AppBarDM{
        .AppCenter{
            grid-template-columns: 1fr 2.2fr 0fr;
        }
        .AppLeft{
            grid-template-columns: 0.25fr 3fr;
        }
    }

    .slide-nav-right{
        width: 11%;
    }


    .item-cardDM-contact-horizontal {
        .item-info-follow {
            .item-info::after {
                left: calc(100% + 14px);
            }
        }
    }

    .section-product {
        top: 5vh
    }


    .title-slide-nav {
        margin-bottom: 8px;
        margin-top: 2px;
        font-size: 20px;
    }

    .body-content-componet-center-dui{
        .item-option-system-dui{
            left: 170px;
        }
    }
}

@media only screen and (min-width: 1920px) {

    .body-content-componet-center-dui{
        width: 100%;
    }


    #header-compont-dui{
        width: calc(100vh + 80px);
    }


    .item-radio-resize-margin,
    .item-radio-resize-padding {
        .radioDM {
            margin-right: 28px;
            margin-left: 4px;
            width: 9%;
        }
    }

    .item-AppBarDM {
        .AppCenter {
            grid-template-columns: 0.6fr 1.5fr;
        }
    }

    .item-cardDM-contact-horizontal {
        .item-info-follow {
            .item-info::after {
                left: calc(100% + 32px);
            }
        }
    }




    .item-cardDM-task-horizontal {

        .item-info-follow {
            .item-info::after {
                left: calc(100% + 24px);
            }
        }
    }

    .item-AppBarDM {
        .AppLeft {
            grid-template-columns: 0.2fr 3fr;
        }
    }

    .section-product {
        top: 5vh;
        height: 66vh;
    }


    .body-content-component {
        margin-top: 80px;
    }

    .body-content-componet-center-dui{
        .item-option-system-dui{
            left: 210px;
        }
    }
}
