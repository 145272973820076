$color_1: #160042;
$color_2: #6b7194;
$color_3: #642eff;
$color_4: #fff;
$font-family_1: Satoshi,
sans-serif;
$font-family_2: Inter,
sans-serif;
$background-color_1: transparent;
$background-color_2: #ddd;
$background-color_3: #fff;
$background-color_4: #f7f5ff;
$background-color_5: #f7f8fd;
$background-color_6: #642eff;
$border-color_1: #642eff;



p {
    color: var(--color-sub);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.w-embed {
    &:before {
        content: " ";
        grid-area: 1/1/2/2;
        display: table;
    }

    &:after {
        content: " ";
        grid-area: 1/1/2/2;
        display: table;
        clear: both;
    }
}

.w-dyn-empty {
    background-color: $background-color_2;
    padding: 10px;
}

.w-dyn-hide {
    display: none !important;
}

.w-dyn-bind-empty {
    display: none !important;
}

.w-condition-invisible {
    display: none !important;
}

.w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.paragraph-regular {
    color: var(--color-sub);
    font-size: 16px;
    line-height: 32px;
}

.paragraph-regular.neutral-900 {
    color: var(--color-title);
}

.section-product-detail-follow-ui {
    padding: 100px 0 160px;

    .item-background-gradient {
        background: var(--img-gradient-mobile) no-repeat;
        width: 100%;
        position: absolute;
        height: 100%;
        background-position: center bottom;
        z-index: -1;
        opacity: 0.5;
    }

    img {
        border: 0;
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
        max-width: 100%;
        display: inline-block;
        border-radius: 12px;
    }
}

.component-grid {
    width: 100%;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-rows: auto;
    grid-template-columns: 2fr 1fr;
}

.overline {
    color: var(--color-sub);
    letter-spacing: .06em;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 12px;
}

.product-purchase-link {
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    transition: border-color .3s;
    display: flex;
    position: relative;
    background: var(--background-color-opacity);
    border: var(--bolor-color-opacity);

    &:hover {
        border-color: #1570EF;
    }
}

.body-button {
    color: var(--color-title);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.p-component-block-detail {
    border-radius: 16px;
    padding: 24px;
    background: var(--background-color-opacity);
    border: var(--bolor-color-opacity);
}

.component-feature-block {
    width: 32px;
    height: 32px;
    background: var(--background-color-opacity);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;

    svg {
        path {
            fill: var(--icon-color-tick);
        }
    }
}

.product-detail-wrapper {
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
}

.store-large-thumbnail {
    border-radius: 12px;
    margin-top: 32px;
    margin-bottom: 16px;
    overflow: hidden;
}

.template-description-wrapper {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    display: flex;

    h1 {
        color: var(--color-title);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    }
}

.component-preview-button {
    grid-column-gap: 8px;
    white-space: nowrap;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: all .3s;
    display: flex;
    margin-top: 24px;
    background: var(--background-color-opacity);
    border: var(--bolor-color-opacity);
    color: var(--color-title);

    &:hover {
        color: white;
        background: #1570EF;
    }
}

.component-thumbnail {
    width: 100%;
}

.template-store-badge {
    color: $color_1;
    letter-spacing: .06em;
    background-color: $background-color_5;
    border-radius: 100px;
    margin-top: 4px;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 700;
}

.tooltip-small {
    margin-left: 4px;
}

.overline-l {
    color: var(--color-sub);
    margin-bottom: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.breadcrumb {
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    border-radius: 12px;
    background: var(--background-color-opacity);
}

.breadcrumb-link {
    color: var(--color-title);
    letter-spacing: -.02em;
    font-weight: 500;
    line-height: 24px;

    &:hover {
        color: var(--color-title);
    }
}

.breadcrumb-link.w--current {
    color: #667085;
}

.icon-regular {
    width: 24px;
    height: 24px;
    align-items: center;
    display: flex;
}

.margin-bottom-16 {
    margin-bottom: 16px;
}

.wrap-h-regular {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    align-items: center;
    display: flex;
}

.wrap-h-x-small {
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
}

.grid-one-column {
    grid-template-columns: 1fr;
}

.lightbox-link {
    overflow: hidden;
}

.collection-list---template-gallery {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-wrap: wrap;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.p-component-button-block {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    border-radius: 16px;
    flex-direction: column;
    padding: 24px;
    display: flex;
    background: var(--background-color-opacity);
    border: var(--bolor-color-opacity);
}

.p-component-column {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-direction: column;
    display: flex;
}

.gallery {
    width: 100%;
    max-width: 296px;
    margin: 40px auto;
    height: 200px;
    margin-bottom: 160px;
    &-slider {
        width: 100%;
        height: auto;
        margin: 0 0 10px 0;

        .swiper-slide {
            width: auto;
            height: 400px;

            img {
                display: block;
                width: auto;
                height: 100%;
                margin: 0 auto;
            }
        }
    }


    &-thumbs {
        width: 100%;
        padding: 0;
        overflow: hidden;

        .swiper-slide {
            width: 100px;
            height: 100px;
            text-align: center;
            overflow: hidden;
            opacity: .1;

            &-active {
                opacity: 1;
            }

            img {
                width: auto;
                height: 100%;
            }
        }
    }
}

.gallery .swiper-container .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Thêm CSS cho swiper-container để làm full màn hình */
.gallery .swiper-container {
    width: 100%;
    height: 100%;
}

.gallery-slider .swiper-slide {
    height: 250px;
}

@media screen and (max-width:991px) {
    .component-grid {
        grid-template-columns: 2fr;
    }
}

@media screen and (max-width:767px) {
    .help {
        display: none;
    }

    .template-description-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .component-preview-button {
        margin-top: 20px;
    }

    .wrap-h-regular {
        flex-wrap: wrap;
    }

    .collection-list---template-gallery {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:479px) {
    h2 {
        font-size: 48px;
        line-height: 60px;
    }

    .overline {
        text-align: left;
    }

    .template-store-badge {
        margin-bottom: 4px;
        margin-left: 0;
    }

    .overline-l {
        text-align: left;
    }
}


@media screen and (min-width:414px) {
	.gallery {
		max-width: 390px;
        height: 300px;
        margin-bottom: 160px;
	}

	.gallery-slider .swiper-slide {
		height: 300px;
	}
}

@media screen and (min-width:768px) {
	.gallery {
		max-width: 744px;
        height: 550px;
	}

	.gallery-slider .swiper-slide {
		height: 550px;
	}
}


@media screen and (min-width:992px) {
	.gallery {
		max-width: 605px;
        height: 500px;
	}

	.gallery-slider .swiper-slide {
		height: 500px;
	}
    .section-product-detail-follow-ui {
        .item-background-gradient{
            background: var(--img-gradient) no-repeat;
            mix-blend-mode: var(--mix-blend-mode);
            filter: blur(128px);
            background-position: center;
        }
    }
}

@media screen and (min-width:1200px) {
	.gallery {
		max-width: 742px;
        height: 620px;
        margin-bottom: 120px;
	}

	.gallery-slider .swiper-slide {
		height: 600px;
	}
}



